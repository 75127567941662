import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./Modulos/Login";
import { ProtectedRoute } from "./Componentes/RutaProtegida";
import { AuthProvider } from "./Hooks/Autorizacion";
import Validacion from "./Modulos/Validar";
import Subir from "./Modulos/Subir";
import PaginaError from "./Modulos/Pagina-No-Existe";
import PPNA from "./Modulos/PPNA";
import Usuarios from "./Modulos/Usuarios";
import Periodos from "./Modulos/Periodos";
import Ocupaciones from "./Modulos/Ocupaciones";
import PrestadorasDeSalud from "./Modulos/PrestadorasDeSalud";
import Auditoria from "./Modulos/Auditoria";
import Envios from "./Modulos/Envios";
import Responsables from "./Modulos/Responsables";
import EncuestaESE from "./Modulos/FormularioSalud";

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<Navigate to="/Login" replace={true} />} />
        <Route path="/Login" element={<Login />} />
        <Route
          path="/Validar"
          element={
            <ProtectedRoute>
              {" "}
              <Validacion />{" "}
            </ProtectedRoute>
          }
        />
        <Route
          path="/Subir"
          element={
            <ProtectedRoute>
              {" "}
              <Subir />{" "}
            </ProtectedRoute>
          }
        />
        <Route
          path="/Auditoria"
          element={
            <ProtectedRoute>
              {" "}
              <Auditoria />{" "}
            </ProtectedRoute>
          }
        />
        <Route
          path="/Usuarios"
          element={
            <ProtectedRoute>
              {" "}
              <Usuarios />{" "}
            </ProtectedRoute>
          }
        />
        <Route
          path="/Responsables"
          element={
            <ProtectedRoute>
              {" "}
              <Responsables />{" "}
            </ProtectedRoute>
          }
        />
        <Route
          path="/Periodos"
          element={
            <ProtectedRoute>
              {" "}
              <Periodos />{" "}
            </ProtectedRoute>
          }
        />
        <Route
          path="/Ocupaciones"
          element={
            <ProtectedRoute>
              {" "}
              <Ocupaciones />{" "}
            </ProtectedRoute>
          }
        />
        <Route
          path="/PrestadorasDeSalud"
          element={
            <ProtectedRoute>
              {" "}
              <PrestadorasDeSalud />{" "}
            </ProtectedRoute>
          }
        />
        <Route
          path="/PPNA"
          element={
            <ProtectedRoute>
              {" "}
              <PPNA />{" "}
            </ProtectedRoute>
          }
        />
        <Route
          path="/Envios"
          element={
            <ProtectedRoute>
              {" "}
              <Envios />{" "}
            </ProtectedRoute>
          }
        />
        <Route
          path="/Encuesta"
          element={
            <ProtectedRoute>
              {" "}
              <EncuestaESE />{" "}
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<PaginaError />} />
      </Routes>
    </AuthProvider>
  );
}

export default App;
