import { useEffect, useState } from "react";
import axios from "axios";

const useFetchEstado = (user) => {
  const [estado, setEstado] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [NombreMuni, setNombreMuni] = useState("");

  useEffect(() => {
    // Realizar la solicitud para obtener el estado del usuario
    async function fetchEstado() {
      try {
        const response = await axios.get("/EstadoCargue", {
          params: {
            correo: user, // Envía el correo del usuario al backend
          },
        });
        const { Estado } = response.data;
        const { Nombre } = response.data;
        setEstado(Estado);
        setNombreMuni(Nombre);
      } catch (error) {
        console.error("Error al obtener la información del usuario:", error);
      } finally {
        setIsLoading(false); // La solicitud ha terminado, por lo que establecemos isLoading en false
      }
    }

    fetchEstado();
  }, [user]);

  return { estado, isLoading, NombreMuni };
};

export default useFetchEstado;
