import React from "react";
import Barra from "../Componentes/Barra";
import { useAuth } from "../Hooks/Autorizacion";
import useFetchUserData from "../Hooks/RedireccionamientoPorRoles";
import axios from "axios";
import { Toaster, toast } from "sonner";

const Auditoria = () => {
  const { user } = useAuth();
  const isLoading = useFetchUserData(user);

  const HandleConsolidado = async (e) => {
    try {
      const response = await axios.get("/GenerarConsolidado", {
        responseType: "blob", // Especificar el tipo de respuesta como un archivo blob
      });

      // Crear un objeto URL del archivo blob recibido
      const url = window.URL.createObjectURL(response.data);

      // Crear un enlace para descargar el archivo
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "ConsolidadoPEDT.TXT"); // Nombre del archivo
      document.body.appendChild(link);
      link.click();

      // Limpiar el objeto URL creado
      window.URL.revokeObjectURL(url);
      toast.warning(
        `Por favor, tener en cuenta que se han identificado duplicidades en algunos registros. Estas duplicidades se han marcado al final de cada registro correspondiente. Se agradece su colaboración en revisar y gestionar estas duplicidades de manera oportuna.`
      );
    } catch (error) {
      console.error("Error al descargar errores:", error);
    }
  };

  return (
    <div className="d-flex flex-column vh-100">
      <Barra />
      {isLoading ? ( // Si isLoading es true, mostrar el spinner
        <div className="d-flex align-items-center justify-content-center vh-100">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Cargando...</span>
          </div>
        </div>
      ) : (
        // Si isLoading es false, mostrar el contenido del módulo
        <div className="d-flex flex-column align-items-center vh-100">
          <h1>Administrar las acciones de los usuarios</h1>
          <form
            autoComplete="off"
            className="d-flex flex-column align-items-center justify-content-center mx-3 w-50"
            style={{
              background: "rgba(7, 137, 48, 0.3)",
              padding: "5%",
              borderRadius: "20px",
            }}
          >
            {" "}
            <button
              type="button"
              className="btn btn-outline-danger"
              onClick={HandleConsolidado}
            >
              Clic aquí para generar y descargar consolidado
            </button>
          </form>
          <Toaster closeButton richColors />
        </div>
      )}
    </div>
  );
};

export default Auditoria;
