import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../Hooks/Autorizacion";
import axios from "axios";

const Barra = () => {
  const user = useAuth();
  const { logout } = useAuth();
  const [isAdmin, setIsAdmin] = useState(false); // Estado para indicar si el usuario es admin

  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    // Realizar la solicitud para obtener la información del usuario y su rol
    async function fetchUserData() {
      try {
        const response = await axios.get("/login/isAdmin", {
          params: {
            correo: user.user, // Envía el correo del usuario al backend
          },
        });
        const { isAdmin } = response.data; // La respuesta incluye un campo isAdmin que indica si el usuario es admin
        if (isAdmin === 1) {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
      } catch (error) {
        console.error("Error al obtener la información del usuario:", error);
      }
    }
    fetchUserData();
  }, [user]);

  return (
    <div>
      <nav
        className="navbar navbar-expand-lg justify-content-around"
        style={{ backgroundColor: "#326939" }}
      >
        <h4 className="font-weight-bold text-light">
          ACTIVIDADES DE PROTECCIÓN ESPECÍFICA Y DETECCIÓN TEMPRANA - PEDT
        </h4>
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link
              className="nav-link text-light"
              to={"/Validar"}
              hidden={isAdmin}
            >
              Validar archivo
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link text-light"
              hidden={isAdmin}
              to={"/Subir"}
            >
              Entregar Firmado
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link text-light"
              hidden={!isAdmin}
              to={"/Auditoria"}
            >
              Auditoria
            </Link>
          </li>
          <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle text-light"
              hidden={isAdmin}
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Consultar tablas
            </Link>
            <ul className="dropdown-menu">
              <li>
                <Link className="dropdown-item" to={"/Ocupaciones"}>
                  Ocupaciones
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to={"/PrestadorasDeSalud"}>
                  Prestadoras de Salud
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to={"/PPNA"}>
                  PPNA
                </Link>
              </li>
            </ul>
          </li>
          <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle text-light"
              hidden={!isAdmin}
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Consultas
            </Link>
            <ul className="dropdown-menu">
              <li>
                <Link className="dropdown-item" to={"/Periodos"}>
                  Periodos
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to={"/Envios"}>
                  Envios
                </Link>
              </li>
            </ul>
          </li>
          <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle text-light"
              hidden={!isAdmin}
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Administración
            </Link>
            <ul className="dropdown-menu">
              <li>
                <Link className="dropdown-item" to={"/Usuarios"}>
                  Usuarios
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to={"/Entidades"}>
                  Entidades
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to={"/Ocupaciones"}>
                  Ocupaciones
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to={"/PrestadorasDeSalud"}>
                  Prestadoras de Salud
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to={"/PPNA"}>
                  PPNA
                </Link>
              </li>
            </ul>
          </li>
          <li className="nav-item">
            <button className="btn btn-outline-warning" onClick={handleLogout}>
              Cerrar Sesión
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Barra;
