import { createContext, useContext, useMemo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./MemoriaLocal";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("", null);
  const navigate = useNavigate();

  const [isLoggedIn, setIsLoggedIn] = useState(false); // Agrega una bandera para rastrear el estado de inicio de sesión

  const login = async (data) => {
    setUser(data);
    navigate("/Validar", { replace: true });
    setIsLoggedIn(true); // Establece la bandera de inicio de sesión como verdadera al iniciar sesión
    resetInactivityTimer();
  };

  const logout = () => {
    setUser(null);
    navigate("", { replace: true });
    setIsLoggedIn(false); // Establece la bandera de inicio de sesión como falsa al cerrar sesión
  };

  let inactivityTimer;

  const resetInactivityTimer = () => {
    clearTimeout(inactivityTimer);
    if (isLoggedIn) {
      // Solo comienza el temporizador si el usuario ha iniciado sesión
      inactivityTimer = setTimeout(logout, 5 * 60 * 1000); // 5 minutos en milisegundos
    }
  };

  // Agregar este manejador de evento para reiniciar el temporizador en actividad del usuario
  const handleUserActivity = () => {
    resetInactivityTimer();
  };

  // Agregar este efecto para escuchar eventos de actividad del usuario
  useEffect(() => {
    // Aquí puedes añadir diferentes eventos dependiendo de la actividad del usuario, por ejemplo, clics, pulsaciones de teclas, etc.
    window.addEventListener("click", handleUserActivity);
    window.addEventListener("keypress", handleUserActivity);

    // Limpiar los eventos al desmontar el componente o cambiar de ruta
    return () => {
      window.removeEventListener("click", handleUserActivity);
      window.removeEventListener("keypress", handleUserActivity);
    };
  }, [isLoggedIn]); // Agrega isLoggedIn a la lista de dependencias para que el efecto se vuelva a ejecutar cuando cambie

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      resetInactivityTimer,
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
