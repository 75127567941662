import React from "react";

const PaginaError = () => {
  return (
    <div
      className="d-flex align-items-center justify-content-center vh-100 mw-100"
      style={{ position: "fixed", zIndex: -1 }}
    >
      <img
        className="d-block img-fluid"
        src={require("../Imgs/Noencontrada.png")}
        alt="Primera img"
      />
    </div>
  );
};

export default PaginaError;
