import React, { useEffect, useState } from "react";
import Barra from "../Componentes/Barra";
import axios from "axios";
import { useAuth } from "../Hooks/Autorizacion";
import useFetchEstado from "../Hooks/EstadoCargue";
import { useNavigate } from "react-router-dom";

const Validacion = () => {
  const Navigate = useNavigate();
  const user = useAuth();
  const { estado, isLoading, NombreMuni } = useFetchEstado(user);
  const [Archivo, setArchivo] = useState(null);
  const [Nombrearchivoinvalido, setNombrearchivoinvalido] = useState(true);
  const [AlertaVisible, setAlertaVisible] = useState(false);
  const [MensajeDeAlerta, setMensajeDeAlerta] = useState("");
  const [AlertaOk, setAlertaOk] = useState(true);
  const [AlertaFail, setAlertaFail] = useState(true);
  const [AlertaBtnError, setBtnError] = useState(true);
  const [DesactivarForm, setDesactivarForm] = useState(false);
  const [BotonPDF, setBotonPDF] = useState(true);
  const [progreso, setProgreso] = useState(0);

  useEffect(() => {
    // Realizar la solicitud para obtener la información del usuario y su rol
    async function fetchUserData() {
      try {
        const response = await axios.get("/login/isAdmin", {
          params: {
            correo: user.user, // Envía el correo del usuario al backend
          },
        });
        const { isAdmin } = response.data; // Suponiendo que la respuesta incluye un campo isAdmin que indica si el usuario es admin
        if (isAdmin === 1) {
          Navigate("/Auditoria", { replace: true });
        } else if (isAdmin === 3) {
          Navigate("/Encuesta", { replace: true });
        }
      } catch (error) {
        console.error("Error al obtener la información del usuario:", error);
      }
    }
    fetchUserData();
    // Si el estado es 2, mostrar un mensaje del archivo siendo validado
    switch (estado) {
      case 2:
        setDesactivarForm(true);
        setAlertaVisible(true);
        setMensajeDeAlerta(
          "Su archivo sigue en revisión, presionar tecla F5"
        );
        setBtnError(false);
        break;
      case 3:
        setMensajeDeAlerta(
          "El último archivo ha sido verificado y contiene errores, por favor corregir y volver a intentar"
        );
        setAlertaVisible(true);
        setAlertaFail(false);
        break;
      case 4:
        setDesactivarForm(true);
        setMensajeDeAlerta(
          "Su archivo ya ha sido verificado y ha pasado todas las validaciones, puedes continuar a subirlo firmado"
        );
        setAlertaOk(false);
        break;
      case 5:
        setDesactivarForm(true);
        setBotonPDF(false);
        setMensajeDeAlerta("Has completado el reporte PEDT exítosamente");
        setAlertaOk(false);
        break;
      default:
        // Si el estado no coincide con ninguno de los casos anteriores
        // No hay necesidad de hacer nada
        break;
    }
  }, [estado]);

  const Manejocambios = async (e) => {
    e.preventDefault();
    setAlertaFail(true);
    setNombrearchivoinvalido(true);
    setMensajeDeAlerta("");
    setAlertaVisible(false);
    if (e.target.files && e.target.files.length > 0) {
      const ArchivoSeleccionado = e.target.files[0];
      setArchivo(ArchivoSeleccionado);
      const Nombredelarchivo = ArchivoSeleccionado.name;
      //Llamar al backend para validar el nombre del archivo
      try {
        const response = await axios.post("/Validacion/Nombre", {
          nombreArchivo: Nombredelarchivo,
          usuario: user,
        });
        if (response.data.success) {
          setArchivo(ArchivoSeleccionado); // Actualiza el estado Archivo con el archivo seleccionado
          setNombrearchivoinvalido(false);
          setAlertaVisible(false);
          setMensajeDeAlerta("");
        } else {
          setNombrearchivoinvalido(true);
          setAlertaVisible(true);
          setMensajeDeAlerta("El nombre del archivo no es válido");
        }
      } catch (error) {
        console.error("Error al validar el nombre del archivo:", error);
      }
    } else {
      setNombrearchivoinvalido(true);
      setAlertaVisible(true);
      setMensajeDeAlerta("No se ha seleccionado un archivo");
    }
  };

  const ManejoSubida = async () => {
    try {
      setBtnError(true);
      // Leer el contenido del archivo seleccionado como base64
      const reader = new FileReader();
      reader.onload = async () => {
        const contenidoBase64 = reader.result.split(",")[1]; // Obtener solo el contenido base64
        const contenidoTexto = atob(contenidoBase64); // Decodificar el contenido base64 a texto
        // Dividir el contenido del archivo en líneas
        const lineas = contenidoTexto.split("\n");
        // Verificar si el archivo tiene al menos una línea
        if (lineas.length <= 1) {
          setAlertaVisible(true);
          setMensajeDeAlerta("No se permiten archivos sin registros");
          setNombrearchivoinvalido(true);
          return;
        } else {
          // Validar cada línea del archivo
          for (let i = 1; i < lineas.length; i++) {
            const linea = lineas[i].trim();
            // Dividir la línea en sus componentes usando el separador "|"
            const componentes = linea.split("|");
            // Verificar si hay exactamente 119 componentes en la línea
            if (componentes.length !== 119) {
              setAlertaVisible(true);
              setMensajeDeAlerta(
                `La línea ${i + 1} no contiene la estructura esperada`
              );
              setNombrearchivoinvalido(true);
              return;
            }
          }
        }
        // Leer la primera línea del archivo
        const primeraLinea = lineas[0].trim();
        // Dividir la primera línea en sus componentes usando el separador "|"
        const componentes = primeraLinea.split("|");
        const [variable1, variable2, variable3, variable4, variable5] =
          componentes;
        const userDigits = user.user.slice(-5);
        // Verificar si hay exactamente 5 componentes en la primera línea
        if (
          componentes.length !== 5 ||
          !/^\d+$/.test(variable5) ||
          variable2 !== userDigits ||
          variable1 !== "1"
        ) {
          setAlertaVisible(true);
          setMensajeDeAlerta(
            "Registro de control inválido, por favor corregir y volver a intentar"
          );
          setNombrearchivoinvalido(true);
          return;
        }
        try {
          // Enviar el contenido base64 y el nombre del archivo al backend
          const response = await axios.post("/Validacion/Contenido", {
            archivo: contenidoBase64,
            nombreArchivo: Archivo.name, // Enviar el nombre del archivo al backend
            usuario: user,
          });

          if (response.data.success) {
            setMensajeDeAlerta(
              "El archivo esta siendo validado."
            );
            setAlertaOk(false);
            setDesactivarForm(true);
            setNombrearchivoinvalido(true);

            iniciarBarraProgreso();

            const validacionResponse = await axios.post("/Validaciones", {
              archivo: contenidoBase64,
              nombreArchivo: Archivo.name,
              usuario: user,
            });

            setMensajeDeAlerta(
              "Su archivo ha sido validado actualice la pagina (presionar tecla F5) para ver el resultado."
            );
          }
        } catch (error) {
          console.error("Error al subir el archivo:", error);

          setMensajeDeAlerta("Ha ocurrido un error durante la validacion. Por favor dar clic en el boton 'Volver al estado inicial'.");

          setBtnError(false);
        }
      };
      reader.readAsDataURL(Archivo);
    } catch (error) {
      console.error("Error al leer el archivo:", error);
    }
  };

  function reiniciar(){
    setDesactivarForm(false);
    setAlertaVisible(false);
    setAlertaFail(true);
    setAlertaOk(true);
    setNombrearchivoinvalido(false);
  }

  const BotonesError = ({ onReset }) => {
    return (
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <button 
          onClick={onReset} 
          style={{
            backgroundColor: 'gray',
            color: 'white',
            padding: '10px 20px',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer'
          }}>
          Volver al estado inicial
        </button>
      </div>
    );
  };

  const iniciarBarraProgreso = () => {
    const intervalId = setInterval(async () => {
      try {
        const response = await axios.get("/Validaciones/Progreso");
        console.log(response.data);
        const { progress } = response.data;
  
        // Actualizar la barra de progreso
        setProgreso(progress);
  
        if (progress >= 99) {
          clearInterval(intervalId);
        }
      } catch (error) {
        console.error("Error al obtener el progreso:", error);
	      clearInterval(intervalId);
      }
    }, 550); // Consultar cada medio segundo
  };

  const BarraDeProgreso = ({ progreso }) => {
    return (
      <div className="barra-de-progreso" style={{ border: '1px solid #ccc', marginTop: '10px', width: '100%', height: '20px' }}>
        <div className="progreso" style={{ width: `${progreso}%`, backgroundColor: 'green', height: '100%' }}></div>
      </div>
    );
  };

  const DescargaErrores = async () => {
    try {
      const response = await axios.get("/BajarErrores", {
        params: {
          correo: {
            user: user,
          },
        },
        responseType: "blob", // Especificar el tipo de respuesta como un archivo blob
      });

      // Crear un objeto URL del archivo blob recibido
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Crear un enlace para descargar el archivo
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "ErroresACorregir.TXT"); // Nombre del archivo
      document.body.appendChild(link);
      link.click();

      // Limpiar el objeto URL creado
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error al descargar errores:", error);
    }
  };

  const DescargaPDF = async () => {
    try {
      const response = await axios.get("/BajarPDF", {
        params: {
          correo: {
            user: user,
          },
        },
        responseType: "blob", // Especificar el tipo de respuesta como un archivo blob
      });

      // Crear un objeto URL del archivo blob recibido
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Crear un enlace para descargar el archivo
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "EntregaPEDT.pdf"); // Nombre del archivo
      document.body.appendChild(link);
      link.click();

      // Limpiar el objeto URL creado
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error al descargar errores:", error);
    }
  };

  return (
    <div className="d-flex flex-column vh-100">
      <Barra />
      {isLoading ? ( // Si isLoading es true, mostrar el spinner
        <div className="d-flex align-items-center justify-content-center vh-100">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Cargando...</span>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column align-items-center justify-content-center vh-100 text-light">
          <div className="d-flex justify-content-center">
            <img
              src={require("../Imgs/logo.png")}
              alt="Logo"
              className="w-25"
            />
          </div>
          <form
            className="form-group text-center p-5"
            style={{ borderRadius: "20px", background: "#326939" }}
          >
            <h1>Subir archivo PEDT para ser validado</h1>
            <label
              className="my-3"
              htmlFor="archivotxt"
              hidden={DesactivarForm}
            >
              Municipio a reportar: {NombreMuni}
            </label>{" "}
            <br />
            <input
              id="archivotxt"
              type="file"
              onChange={Manejocambios}
              className="form-control-file"
              accept=".txt"
              hidden={DesactivarForm}
            />
            <div
              className="justify-content-center alert alert-warning my-3"
              role="alert"
              hidden={!AlertaVisible}
            >
              {MensajeDeAlerta}

              <div
                hidden={AlertaBtnError}
              >
                <BotonesError onReset={reiniciar} />
              </div>
            </div>
            <div className="form-group text-center mt-3">
              <button
                type="button"
                onClick={ManejoSubida}
                hidden={Nombrearchivoinvalido}
                className="btn btn-outline-light"
              >
                Enviar Archivo
              </button>
              <div
                className="alert alert-success mt-3"
                role="alert"
                hidden={AlertaOk}
              >
                {MensajeDeAlerta}

                <BarraDeProgreso progreso={progreso} />
                <div
                  hidden={AlertaBtnError}
                >
                  <BotonesError onReset={reiniciar} />
                </div>
              </div>
              <button
                type="button"
                onClick={DescargaErrores}
                hidden={AlertaFail}
                className="btn btn-outline-info mx-3"
              >
                Clic aquí para descargar archivo de errores del intento anterior
              </button>
              <button
                type="button"
                className="btn btn-outline-danger"
                onClick={DescargaPDF}
                hidden={BotonPDF}
              >
                Clic aquí para descargar PDF
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default Validacion;
