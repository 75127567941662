import { useEffect, useState } from 'react';

const Carrousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Lista de imágenes
  const importAllImages = (requireContext) => requireContext.keys().map(requireContext);
  const images = importAllImages(require.context('../Imgs/Carousel', false, /\.(png|jpe?g|svg|JPG)$/));

  useEffect(() => {
    const interval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * images.length);
      setCurrentIndex(randomIndex);
    }, 6000);
    return () => clearInterval(interval);
  }, [images.length]);

  // Estilos en línea para el contenedor y el fondo
  const styles = {
    container: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      zIndex: -1,
      overflow: 'hidden',
    },
    background: {
      width: '100%',
      height: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      transition: 'background-image 1s ease-in-out',
      backgroundImage: `url(${images[currentIndex]})`, // Imagen actual
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.background}></div>
    </div>
  );
};

export default Carrousel;
