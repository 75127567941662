import React, { useState, useEffect } from "react";
import useFetchUserData from "../Hooks/RedireccionamientoPorRoles";
import { useAuth } from "../Hooks/Autorizacion";
import axios from "axios";
import { Toaster, toast } from "sonner";

const EncuestaESE = () => {
  const [Bloqueo, setBloqueo] = useState(false);
  const [NumSedeAEditar, setNumSedeAEditar] = useState("");
  const [NumeroSede, setNumeroSede] = useState("");
  const [CorreoSede, setCorreoSede] = useState("");
  const [MostrarOtrasDiccionario, setMostrarOtrasDiccionario] = useState(false);
  const [OtrasDiccionario, setOtrasDiccionario] = useState("");
  const [Diccionario, setDiccionario] = useState("");
  const [MostrarOtrasFormato, setMostrarOtrasFormato] = useState(false);
  const [OtrasFormato, setOtrasFormato] = useState("");
  const [Formato, setFormato] = useState("");
  const [MostrarOtraGarantiza, setMostrarOtraGarantiza] = useState(false);
  const [OtrasGarantiza, setOtrasGarantiza] = useState("");
  const [Garantiza, setGarantiza] = useState("");
  const [Mecanismo, setMecanismo] = useState("");
  const [EstandarProtocoloOtro, setEstandarProtocoloOtro] = useState("");
  const [MostrarCampoOtropro, setMostrarCampoOtropro] = useState(false);
  const [EstandarProtocolo, setEstandarProtocolo] = useState("");
  const [MedidaEvaluativa, setMedidaEvaluativa] = useState("");
  const [ProcesoEstablecido, setProcesoEstablecido] = useState("");
  const [Estrategia, setEstrategia] = useState("");
  const [plataformasIntegracion, setPlataformasIntegracion] = useState(false);
  const [softwarePropositoGeneral, setSoftwarePropositoGeneral] =
    useState(false);
  const [sinHerramientas, setSinHerramientas] = useState(false);
  const [noSabeHerra, setNoSabeHerra] = useState(false);
  const [OtrasHerra, setOtrasHerra] = useState("");
  const [estadoSalud, setEstadoSalud] = useState(false);
  const [solicitudesAtencion, setSolicitudesAtencion] = useState(false);
  const [resumenAtencion, setResumenAtencion] = useState(false);
  const [medicamentosRecetados, setMedicamentosRecetados] = useState(false);
  const [resultadosAnalisis, setResultadosAnalisis] = useState(false);
  const [resultadosImagenes, setResultadosImagenes] = useState(false);
  const [planesCuidado, setPlanesCuidado] = useState(false);
  const [ningunaInfo, setNingunaInfo] = useState(false);
  const [OtraInfo, setOtraInfo] = useState("");
  const [monitoreoRegistroSeguridad, setMonitoreoRegistroSeguridad] =
    useState("");
  const [integridadSeguridadDatos, setIntegridadSeguridadDatos] = useState("");
  const [firmaDigital, setFirmaDigital] = useState(false);
  const [archivosCriptografiados, setArchivosCriptografiados] = useState(false);
  const [proteccionContraseñaArchivos, setProteccionContraseñaArchivos] =
    useState(false);
  const [proteccionContraseñaSistema, setProteccionContraseñaSistema] =
    useState(false);
  const [antivirus, setAntivirus] = useState(false);
  const [firewallSE, setFirewallSE] = useState(false);
  const [criptografiaBaseDatos, setCriptografiaBaseDatos] = useState(false);
  const [certificadoDigital, setCertificadoDigital] = useState(false);
  const [accesoBiometrico, setAccesoBiometrico] = useState(false);
  const [dobleFactor, setDobleFactor] = useState(false);
  const [dlp, setDlp] = useState(false);
  const [ninguna, setNinguna] = useState(false);
  const [ConexionDiscado, setConexionDiscado] = useState(false);
  const [ConexionDSL, setConexionDSL] = useState(false);
  const [ConexionCable, setConexionCable] = useState(false);
  const [ConexionFibraOptica, setConexionFibraOptica] = useState(false);
  const [ConexionSatelital, setConexionSatelital] = useState(false);
  const [ConexionRadio, setConexionRadio] = useState(false);
  const [ConexionMovil, setConexionMovil] = useState(false);
  const [colaboracionTI, setColaboracionTI] = useState("");
  const [RolesEspecificos, setRolesEspecificos] = useState("");
  const [equipoTIDedicado, setEquipoTIDedicado] = useState("");
  const [AmbientesSeparados, setAmbientesSeparados] = useState("");
  const [OtrasNube, setOtrasNube] = useState("");
  const [IaaS, setIaaS] = useState(false);
  const [PaaS, setPaaS] = useState(false);
  const [SaaS, setSaaS] = useState(false);
  const [AlmaNube, setAlmaNube] = useState(false);
  const [CorreoNube, setCorreoNube] = useState(false);
  const [OficinaNube, setOficinaNube] = useState(false);
  const [DBNube, setDBNube] = useState(false);
  const [ProcesamientoNube, setProcesamientoNube] = useState(false);
  const [CuentaConNube, setCuentaConNube] = useState("");
  const [Escalable, setEscalable] = useState(false);
  const [AltaDisponibilidad, setAltaDisponibilidad] = useState(false);
  const [Seguridad, setSeguridad] = useState(false);
  const [ConectividadRobusta, setConectividadRobusta] = useState(false);
  const [GestionCentralizada, setGestionCentralizada] = useState(false);
  const [Virtualizacion, setVirtualizacion] = useState(false);
  const [RespaldoRecuperacion, setRespaldoRecuperacion] = useState(false);
  const [OtrasData, setOtrasData] = useState("");
  const [CuentaConDataCenter, setCuentaConDataCenter] = useState("");
  const [InfoDemo, setInfoDemo] = useState("");
  const [Diagnosticos, setDiagnosticos] = useState("");
  const [Antecedentes, setAntecedentes] = useState("");
  const [Alergias, setAlergias] = useState("");
  const [SignosVitales, setSignosVitales] = useState("");
  const [Medicamentos, setMedicamentos] = useState("");
  const [Vacunas, setVacunas] = useState("");
  const [NotasEnfermeria, setNotasEnfermeria] = useState("");
  const [Resultados, setResultados] = useState("");
  const [InformesRadiologicos, setInformesRadiologicos] = useState("");
  const [ImagesRadiologicos, setImagesRadiologicos] = useState("");
  const [Detalles, setDetalles] = useState("");
  const [RegistrosPrevios, setRegistrosPrevios] = useState("");
  const [OtrosRegistros, setOtrosRegistros] = useState("");
  const [NombrePerSistema, setNombrePerSistema] = useState("");
  const [CorreoPerSistema, setCorreoPerSistema] = useState("");
  const [NumeroPerSistema, setNumeroPerSistema] = useState("");
  const [CargoPerSistema, setCargoPerSistema] = useState("");
  const [Modalidad, setModalidad] = useState("");
  const [NivelComplejidad, setNivelComplejidad] = useState("");
  const [CodHabSede, setCodHabSede] = useState("");
  const [NombreResponsable, setNombreResponsable] = useState("");
  const [CorreoRespon, setCorreoRespon] = useState("");
  const [NumeroRes, setNumeroRes] = useState("");
  const [CargoRes, setCargoRes] = useState("");
  const [CargoGerenteESE, setCargoGerenteESE] = useState("");
  const [FechaVence, setFechaVence] = useState("");
  const [mostrarCampoOtro, setMostrarCampoOtro] = useState(false);
  const [proveedorOtro, setProveedorOtro] = useState("");
  const [ModeloFirewall, setModeloFirewall] = useState("");
  const [CuentaConTecnicoESE, setCuentaConTecnicoESE] = useState("");
  const [NombreTecnicoESE, setNombreTecnicoESE] = useState("");
  const [CorreoTecnicoESE, setCorreoTecnicoESE] = useState("");
  const [NumeroTecnicoESE, setNumeroTecnicoESE] = useState("");
  const [CargoTecnicoESE, setCargoTecnicoESE] = useState("");
  const [gradosLatitud, setGradosLatitud] = useState("");
  const [minutosLatitud, setMinutosLatitud] = useState("");
  const [segundosLatitud, setSegundosLatitud] = useState("");
  const [latitudDecimal, setLatitudDecimal] = useState("");
  const [gradosLongitud, setGradosLongitud] = useState("");
  const [minutosLongitud, setMinutosLongitud] = useState("");
  const [segundosLongitud, setSegundosLongitud] = useState("");
  const [longitudDecimal, setLongitudDecimal] = useState("");
  const [SedeNuevaHabilitada, setSedeNuevaHabilitada] = useState("");
  const [ESESeleccionada, setESESeleccionada] = useState("");
  const [NumeroESE, setNumeroESE] = useState("");
  const [infraestructuraRed, setInfraestructuraRed] = useState("");
  const [OtraConexion, setOtraConexion] = useState("");
  const [anchoBanda, setAnchoBanda] = useState("");
  const [proveedorConexion, setProveedorConexion] = useState("");
  const [firewall, setFirewall] = useState("");
  const [FabricanteFirewall, setFabricanteFirewall] = useState("");
  const [versionFirewall, setVersionFirewall] = useState("");
  const [PersonalVPN, setPersonalVPN] = useState("");
  const [software, setSoftware] = useState("");
  const [NombreSoftware, setNombreSoftware] = useState("");
  const [TipoLicencia, setTipoLicencia] = useState("");
  const [UltimaVersionSoftware, setUltimaVersionSoftware] = useState("");
  const [versionSoftware, setVersionSoftware] = useState("");
  const [ContratoSoftware, setContratoSoftware] = useState("");
  const [FabricanteSoftware, setFabricanteSoftware] = useState("");
  const [datos, setDatos] = useState();
  const [ESE, setESE] = useState();
  const { user, logout } = useAuth();
  const isLoading = useFetchUserData(user);
  const [SedeSeleccionada, setSedeSeleccionada] = useState("");
  const [CuentaConTecnico, setCuentaConTecnico] = useState("");
  const [NombreTecnico, setNombreTecnico] = useState("");
  const [CorreoTecnico, setCorreoTecnico] = useState("");
  const [NumeroTecnico, setNumeroTecnico] = useState("");
  const [CuentaConAdministrativo, setCuentaConAdministrativo] = useState("");
  const [NombreAdministrativo, setNombreAdministrativo] = useState("");
  const [CorreoAdministrativo, setCorreoAdministrativo] = useState("");
  const [NumeroAdministrativo, setNumeroAdministrativo] = useState("");
  const [NombreSede, setNombreSede] = useState("");
  const [DireccionSede, setDireccionSede] = useState("");
  const [UltimaVersionFirewall, setUltimaVersionFirewall] = useState("");
  const [NombreVPN, setNombreVPN] = useState("");
  const [CorreoVPN, setCorreoVPN] = useState("");
  const [NumeroVPN, setNumeroVPN] = useState("");
  const [ContratistaVPN, setContratistaVPN] = useState("");
  const [NombreContratistaVPN, setNombreContratistaVPN] = useState("");
  const [CorreoContratistaVPN, setCorreoContratistaVPN] = useState("");
  const [NumeroContratistaVPN, setNumeroContratistaVPN] = useState("");
  const [CorreoComercial, setCorreoComercial] = useState("");
  const [CorreoAsistencia, setCorreoAsistencia] = useState("");
  const [NumeroAsistencia, setNumeroAsistencia] = useState("");
  const [NumeroComercial, setNumeroComercial] = useState("");
  const [NumeroNuevaSede, setNumeroNuevaSede] = useState("");
  const [NombreESE, setNombreESE] = useState("");
  const [CodHabESE, setCodHabESE] = useState("");
  const [DireccionESE, setDireccionESE] = useState("");
  const [CorreoESE, setCorreoESE] = useState("");
  const [GerenteLegalESE, setGerenteLegalESE] = useState("");
  const [CorreoGerenteESE, setCorreoGerenteESE] = useState("");
  const [NumeroGerenteESE, setNumeroGerenteESE] = useState("");
  const totalSedesPosibles = 99; // Ajusta este número según el máximo de sedes posibles
  const numerosSedesDisponibles = [];
  if (datos) {
    const numerosSedesUsadas = datos.map((dato) =>
      parseInt(dato.NumeroSede, 10)
    );
    for (let i = 1; i <= totalSedesPosibles; i++) {
      const numeroSede = i.toString().padStart(2, "0");
      if (!numerosSedesUsadas.includes(i)) {
        numerosSedesDisponibles.push(numeroSede);
      }
    }
  }

  const handleDiccionarioChange = (e) => {
    const diccionario = e.target.value;
    if (diccionario === "OTRAS") {
      setMostrarOtrasDiccionario(true);
      setDiccionario("OTRAS");
    } else {
      setMostrarOtrasDiccionario(false);
      setDiccionario(diccionario);
    }
  };
  const handleFormatoChange = (e) => {
    const formato = e.target.value;
    if (formato === "OTRAS") {
      setMostrarOtrasFormato(true);
      setFormato("OTRAS");
    } else {
      setMostrarOtrasFormato(false);
      setFormato(formato);
    }
  };
  const handleGarantizaChange = (e) => {
    const garantiza = e.target.value;
    if (garantiza === "OTRAS") {
      setMostrarOtraGarantiza(true);
      setGarantiza("OTRAS");
    } else {
      setMostrarOtraGarantiza(false);
      setGarantiza(garantiza);
    }
  };
  const handleProtocoloChange = (e) => {
    const protocolo = e.target.value;
    if (protocolo === "OTRO") {
      setMostrarCampoOtropro(true);
      setEstandarProtocolo("OTRO");
    } else {
      setMostrarCampoOtropro(false);
      setEstandarProtocolo(protocolo);
    }
  };
  const handleProveedorChange = (e) => {
    const proveedor = e.target.value;
    if (proveedor === "OTRO") {
      setMostrarCampoOtro(true);
      setProveedorConexion("OTRO");
    } else {
      setMostrarCampoOtro(false);
      setProveedorConexion(proveedor);
    }
  };
  const TraerEstandares = async (num, codha) => {
    const ResponseEstandares = await axios.get("/DatosSedeEstandares", {
      params: {
        CodigoPrestador: user,
        NumeroSede: num,
        CodigoHabilitacion: codha,
      },
    });
    return ResponseEstandares.data[0];
  };
  const TraerHerramientas = async (num, codha) => {
    const ResponseHerramientas = await axios.get("/DatosSedeHerramientas", {
      params: {
        CodigoPrestador: user,
        NumeroSede: num,
        CodigoHabilitacion: codha,
      },
    });
    return ResponseHerramientas.data[0];
  };
  const TraerInfoCompartida = async (num, codha) => {
    const ResponseInfoCompartida = await axios.get("/DatosSedeInfoCompartida", {
      params: {
        CodigoPrestador: user,
        NumeroSede: num,
        CodigoHabilitacion: codha,
      },
    });
    return ResponseInfoCompartida.data[0];
  };
  const TraerSeguridad = async (num, codha) => {
    const ResponseSeguridad = await axios.get("/DatosSedeSeguridad", {
      params: {
        CodigoPrestador: user,
        NumeroSede: num,
        CodigoHabilitacion: codha,
      },
    });
    return ResponseSeguridad.data[0];
  };
  const TraerTalentoHumano = async (num, codha) => {
    const ResponseTalentoHumano = await axios.get("/DatosSedeTalento", {
      params: {
        CodigoPrestador: user,
        NumeroSede: num,
        CodigoHabilitacion: codha,
      },
    });
    return ResponseTalentoHumano.data[0];
  };
  const TraerServiciosNube = async (num, codha) => {
    const ResponseServiciosNube = await axios.get("/DatosSedeNube", {
      params: {
        CodigoPrestador: user,
        NumeroSede: num,
        CodigoHabilitacion: codha,
      },
    });
    return ResponseServiciosNube.data[0];
  };
  const TraerInfraestructura = async (num, codha) => {
    const ResponseInfraestructura = await axios.get(
      "/DatosSedeInfraestructura",
      {
        params: {
          CodigoPrestador: user,
          NumeroSede: num,
          CodigoHabilitacion: codha,
        },
      }
    );
    return ResponseInfraestructura.data[0];
  };
  const TraerInfoIncluida = async (num, codha) => {
    const ResponseInfoIncluida = await axios.get("/DatosSedeInfoIncluida", {
      params: {
        CodigoPrestador: user,
        NumeroSede: num,
        CodigoHabilitacion: codha,
      },
    });
    return ResponseInfoIncluida.data[0];
  };
  const TraerContactoSoftware = async (num, codha) => {
    const ResponseContactoSoftware = await axios.get(
      "/DatosSedeContactoSoftware",
      {
        params: {
          CodigoPrestador: user,
          NumeroSede: num,
          CodigoHabilitacion: codha,
        },
      }
    );
    return ResponseContactoSoftware.data[0];
  };
  const TraerSoftware = async (num, codha) => {
    const ResponseSoftware = await axios.get("/DatosSedeSoftware", {
      params: {
        CodigoPrestador: user,
        NumeroSede: num,
        CodigoHabilitacion: codha,
      },
    });
    return ResponseSoftware.data[0];
  };
  const TraerContratistaVPN = async (num, codha) => {
    const ResponseContratistaVPN = await axios.get("/DatosSedeContratistaVPN", {
      params: {
        CodigoPrestador: user,
        NumeroSede: num,
        CodigoHabilitacion: codha,
      },
    });
    return ResponseContratistaVPN.data[0];
  };
  const TraerPersonalVPN = async (num, codha) => {
    const ResponsePersonalVPN = await axios.get("/DatosSedePersonalVPN", {
      params: {
        CodigoPrestador: user,
        NumeroSede: num,
        CodigoHabilitacion: codha,
      },
    });
    return ResponsePersonalVPN.data[0];
  };

  const TraerCA = async (num, codha) => {
    const ResponsecontactoAdmin = await axios.get("/DatosSedeCA", {
      params: {
        CodigoPrestador: user,
        NumeroSede: num,
        CodigoHabilitacion: codha,
      },
    });
    return ResponsecontactoAdmin.data[0];
  };
  const TraerConectividad = async (num, codha) => {
    const ResponseConexion = await axios.get("/DatosSedeConectividad", {
      params: {
        CodigoPrestador: user,
        NumeroSede: num,
        CodigoHabilitacion: codha,
      },
    });
    return ResponseConexion.data[0];
  };
  const TraerCT = async (num, codha) => {
    const ResponsecontactoTecnico = await axios.get("/DatosSedeCT", {
      params: {
        CodigoPrestador: user,
        NumeroSede: num,
        CodigoHabilitacion: codha,
      },
    });
    return ResponsecontactoTecnico.data[0];
  };
  const TraerFirewall = async (num, codha) => {
    const ResponseFirewall = await axios.get("/DatosSedeFirewall", {
      params: {
        CodigoPrestador: user,
        NumeroSede: num,
        CodigoHabilitacion: codha,
      },
    });
    return ResponseFirewall.data[0];
  };
  const TraerCoordenadas = async (num, codha) => {
    const ResponseCoordenadas = await axios.get("/Coordenadas", {
      params: {
        CodigoPrestador: user,
        NumeroSede: num,
        CodigoHabilitacion: codha,
      },
    });
    return ResponseCoordenadas.data[0];
  };
  const TraerPreguntasGenerales = async (num, codha) => {
    const ResponseGenerales = await axios.get("/Generales", {
      params: {
        CodigoPrestador: user,
        NumeroSede: num,
        CodigoHabilitacion: codha,
      },
    });
    return ResponseGenerales.data[0];
  };

  const handleEditarSede = async (num) => {
    try {
      const Response = await axios.get("/DatosSede", {
        params: { CodigoPrestador: user, NumeroSede: num },
      });
      const DatosSede = Response.data[0];
      setNombreSede(DatosSede.NombreSede === null ? "" : DatosSede.NombreSede);
      setCodHabSede(
        DatosSede.CodigoHabilitacion === null
          ? ""
          : DatosSede.CodigoHabilitacion + DatosSede.NumeroSede
      );
      setDireccionSede(DatosSede.Direccion === null ? "" : DatosSede.Direccion);
      if (DatosSede.Complejidad !== null) {
        setNivelComplejidad(DatosSede.Nivel);
      }
      setCorreoSede(DatosSede.Correo === null ? "" : DatosSede.Correo);
      setNumeroSede(DatosSede.Telefono === null ? "" : DatosSede.Telefono);

      const DatosCoordenadas = await TraerCoordenadas(
        num,
        DatosSede.CodigoHabilitacion
      );
      setGradosLatitud(
        DatosCoordenadas.LatGrados === null ? "" : DatosCoordenadas.LatGrados
      );
      setMinutosLatitud(
        DatosCoordenadas.LatMinutos === null ? "" : DatosCoordenadas.LatMinutos
      );
      setSegundosLatitud(
        DatosCoordenadas.LatSegundos === null
          ? ""
          : DatosCoordenadas.LatSegundos
      );
      setLatitudDecimal(
        DatosCoordenadas.LatDecimal === null ? "" : DatosCoordenadas.LatDecimal
      );
      setGradosLongitud(
        DatosCoordenadas.LongGrados === null ? "" : DatosCoordenadas.LongGrados
      );
      setMinutosLongitud(
        DatosCoordenadas.LongMinutos === null
          ? ""
          : DatosCoordenadas.LongMinutos
      );
      setSegundosLongitud(
        DatosCoordenadas.LongSegundos === null
          ? ""
          : DatosCoordenadas.LongSegundos
      );
      setLongitudDecimal(
        DatosCoordenadas.LongDecimal === null
          ? ""
          : DatosCoordenadas.LongDecimal
      );

      const DatosPreguntasGenerales = await TraerPreguntasGenerales(
        num,
        DatosSede.CodigoHabilitacion
      );
      setCuentaConTecnico(
        DatosPreguntasGenerales.TieneTecnicoSede === null
          ? false
          : DatosPreguntasGenerales.TieneTecnicoSede
      );
      setCuentaConAdministrativo(
        DatosPreguntasGenerales.TieneAdminSede === null
          ? false
          : DatosPreguntasGenerales.TieneAdminSede
      );
      setInfraestructuraRed(
        DatosPreguntasGenerales.TieneConectividadSede === null
          ? false
          : DatosPreguntasGenerales.TieneConectividadSede
      );
      setFirewall(
        DatosPreguntasGenerales.TieneFirewallSede === null
          ? false
          : DatosPreguntasGenerales.TieneFirewallSede
      );
      setPersonalVPN(
        DatosPreguntasGenerales.TienePersonalVPNSede === null
          ? false
          : DatosPreguntasGenerales.TienePersonalVPNSede
      );
      setContratistaVPN(
        DatosPreguntasGenerales.TieneContratistaVPNSede === null
          ? false
          : DatosPreguntasGenerales.TieneContratistaVPNSede
      );
      setSoftware(
        DatosPreguntasGenerales.TieneSoftwareSede === null
          ? false
          : DatosPreguntasGenerales.TieneSoftwareSede
      );

      const DatosCT = await TraerCT(num, DatosSede.CodigoHabilitacion);
      setNombreTecnico(
        DatosCT.NombreContacto === null ? "" : DatosCT.NombreContacto
      );
      setNumeroTecnico(
        DatosCT.TelefonoContacto === null ? "" : DatosCT.TelefonoContacto
      );
      setCorreoTecnico(
        DatosCT.CorreoContacto === null ? "" : DatosCT.CorreoContacto
      );

      const DatosCA = await TraerCA(num, DatosSede.CodigoHabilitacion);
      setNombreAdministrativo(
        DatosCA.NombreContacto === null ? "" : DatosCA.NombreContacto
      );
      setNumeroAdministrativo(
        DatosCA.TelefonoContacto === null ? "" : DatosCA.TelefonoContacto
      );
      setCorreoAdministrativo(
        DatosCA.CorreoContacto === null ? "" : DatosCA.CorreoContacto
      );

      const DatosConexion = await TraerConectividad(
        num,
        DatosSede.CodigoHabilitacion
      );
      setConexionDiscado(
        DatosConexion.ConexionDiscado === null ||
          DatosConexion.ConexionDiscado === false
          ? false
          : true
      );
      setConexionDSL(
        DatosConexion.ConexionDSL === null ||
          DatosConexion.ConexionDSL === false
          ? false
          : true
      );
      setConexionCable(
        DatosConexion.ConexionCable === null ||
          DatosConexion.ConexionCable === false
          ? false
          : true
      );
      setConexionFibraOptica(
        DatosConexion.ConexionFibraOptica === null ||
          DatosConexion.ConexionFibraOptica === false
          ? false
          : true
      );
      setConexionSatelital(
        DatosConexion.ConexionSatelital === null ||
          DatosConexion.ConexionSatelital === false
          ? false
          : true
      );
      setConexionRadio(
        DatosConexion.ConexionRadio === null ||
          DatosConexion.ConexionRadio === false
          ? false
          : true
      );
      setConexionMovil(
        DatosConexion.Movilo3G4G === null || DatosConexion.Movilo3G4G === false
          ? false
          : true
      );
      setOtraConexion(
        DatosConexion.OtroTipoConexion === null
          ? ""
          : DatosConexion.OtroTipoConexion
      );
      setProveedorConexion(
        DatosConexion.ProveedorInternet === null
          ? ""
          : DatosConexion.ProveedorInternet
      );
      setProveedorOtro(
        DatosConexion.OtroProveedor === null ? "" : DatosConexion.OtroProveedor
      );
      if (DatosConexion.ProveedorInternet === "Otro") {
        setMostrarCampoOtro(true);
      }
      setAnchoBanda(
        DatosConexion.AnchoBanda === null ? "" : DatosConexion.AnchoBanda
      );

      const DatosFirewall = await TraerFirewall(
        num,
        DatosSede.CodigoHabilitacion
      );
      setFabricanteFirewall(
        DatosFirewall.Fabricante === null ? "" : DatosFirewall.Fabricante
      );
      setModeloFirewall(
        DatosFirewall.Modelo === null ? "" : DatosFirewall.Modelo
      );
      setUltimaVersionFirewall(
        DatosFirewall.Actualizado === null ? false : DatosFirewall.Actualizado
      );
      setVersionFirewall(
        DatosFirewall.VersionActual === null ? "" : DatosFirewall.VersionActual
      );

      const DatosPersonalVPN = await TraerPersonalVPN(
        num,
        DatosSede.CodigoHabilitacion
      );
      setNombreVPN(
        DatosPersonalVPN.NombrePersonalVPN === null
          ? ""
          : DatosPersonalVPN.NombrePersonalVPN
      );
      setCorreoVPN(
        DatosPersonalVPN.CorreoPersonalVPN === null
          ? ""
          : DatosPersonalVPN.CorreoPersonalVPN
      );
      setNumeroVPN(
        DatosPersonalVPN.TelefonoPersonalVPN === null
          ? ""
          : DatosPersonalVPN.TelefonoPersonalVPN
      );

      const DatosContratistaVPN = await TraerContratistaVPN(
        num,
        DatosSede.CodigoHabilitacion
      );
      setNombreContratistaVPN(
        DatosContratistaVPN.NombreContratista === null
          ? ""
          : DatosContratistaVPN.NombreContratista
      );
      setCorreoContratistaVPN(
        DatosContratistaVPN.CorreoContratista === null
          ? ""
          : DatosContratistaVPN.CorreoContratista
      );
      setNumeroContratistaVPN(
        DatosContratistaVPN.TelefonoContratista === null
          ? ""
          : DatosContratistaVPN.TelefonoContratista
      );

      const DatosSoftware = await TraerSoftware(
        num,
        DatosSede.CodigoHabilitacion
      );
      setNombreSoftware(
        DatosSoftware.NombreSoftware === null
          ? ""
          : DatosSoftware.NombreSoftware
      );
      setFabricanteSoftware(
        DatosSoftware.Proveedor === null ? "" : DatosSoftware.Proveedor
      );
      setModalidad(
        DatosSoftware.Modalidad === null ? "" : DatosSoftware.Modalidad
      );
      setTipoLicencia(
        DatosSoftware.Licenciamiento === null
          ? ""
          : DatosSoftware.Licenciamiento
      );
      setCorreoComercial(
        DatosSoftware.CorreoComercialProveedor === null
          ? ""
          : DatosSoftware.CorreoComercialProveedor
      );
      setCorreoAsistencia(
        DatosSoftware.CorreoTecnicoProveedor === null
          ? ""
          : DatosSoftware.CorreoTecnicoProveedor
      );
      setNumeroComercial(
        DatosSoftware.NumeroComercialProveedor === null
          ? ""
          : DatosSoftware.NumeroComercialProveedor
      );
      setNumeroAsistencia(
        DatosSoftware.NumeroTecnicoProveedor === null
          ? ""
          : DatosSoftware.NumeroTecnicoProveedor
      );
      setUltimaVersionSoftware(
        DatosSoftware.Actualizado === null ||
          DatosSoftware.Actualizado === false
          ? false
          : true
      );
      setVersionSoftware(
        DatosSoftware.Version === null ? "" : DatosSoftware.Version
      );
      setContratoSoftware(
        DatosSoftware.TieneContrato === null ||
          DatosSoftware.TieneContrato === false
          ? false
          : true
      );
      setFechaVence(
        DatosSoftware.FechafinContrato === null
          ? ""
          : DatosSoftware.FechafinContrato
      );

      const DatosContactoSoftware = await TraerContactoSoftware(
        num,
        DatosSede.CodigoHabilitacion
      );
      setNombrePerSistema(
        DatosContactoSoftware.NombreContacto === null
          ? ""
          : DatosContactoSoftware.NombreContacto
      );
      setCorreoPerSistema(
        DatosContactoSoftware.CorreoContacto === null
          ? ""
          : DatosContactoSoftware.CorreoContacto
      );
      setNumeroPerSistema(
        DatosContactoSoftware.TelefonoContacto === null
          ? ""
          : DatosContactoSoftware.TelefonoContacto
      );
      setCargoPerSistema(
        DatosContactoSoftware.CargoContacto === null
          ? ""
          : DatosContactoSoftware.CargoContacto
      );

      const DatosInfoIncluida = await TraerInfoIncluida(
        num,
        DatosSede.CodigoHabilitacion
      );
      setInfoDemo(
        DatosInfoIncluida.InfoDemografica === null ||
          DatosInfoIncluida.InfoDemografica === false
          ? false
          : true
      );
      setDiagnosticos(
        DatosInfoIncluida.Diagnosticos === null ||
          DatosInfoIncluida.Diagnosticos === false
          ? false
          : true
      );
      setAntecedentes(
        DatosInfoIncluida.Antecedentes === null ||
          DatosInfoIncluida.Antecedentes === false
          ? false
          : true
      );
      setAlergias(
        DatosInfoIncluida.Alergias === null ||
          DatosInfoIncluida.Alergias === false
          ? false
          : true
      );
      setSignosVitales(
        DatosInfoIncluida.SignosVitales === null ||
          DatosInfoIncluida.SignosVitales === false
          ? false
          : true
      );
      setMedicamentos(
        DatosInfoIncluida.MedicamentosRecetados === null ||
          DatosInfoIncluida.MedicamentosRecetados === false
          ? false
          : true
      );
      setVacunas(
        DatosInfoIncluida.RegistroVacunas === null ||
          DatosInfoIncluida.RegistroVacunas === false
          ? false
          : true
      );
      setNotasEnfermeria(
        DatosInfoIncluida.NotasEnfermeria === null ||
          DatosInfoIncluida.NotasEnfermeria === false
          ? false
          : true
      );
      setResultados(
        DatosInfoIncluida.ResulAnalisis === null ||
          DatosInfoIncluida.ResulAnalisis === false
          ? false
          : true
      );
      setInformesRadiologicos(
        DatosInfoIncluida.InformesRadiologicos === null ||
          DatosInfoIncluida.InformesRadiologicos === false
          ? false
          : true
      );
      setImagesRadiologicos(
        DatosInfoIncluida.ImagenesRadiologicos === null ||
          DatosInfoIncluida.ImagenesRadiologicos === false
          ? false
          : true
      );
      setDetalles(
        DatosInfoIncluida.AdmisionTrasladoAlta === null ||
          DatosInfoIncluida.AdmisionTrasladoAlta === false
          ? false
          : true
      );
      setRegistrosPrevios(
        DatosInfoIncluida.RegistrosPrevios === null ||
          DatosInfoIncluida.RegistrosPrevios === false
          ? false
          : true
      );
      setOtrosRegistros(
        DatosInfoIncluida.Otros === null ? "" : DatosInfoIncluida.Otros
      );

      const DatosInfaestructura = await TraerInfraestructura(
        num,
        DatosSede.CodigoHabilitacion
      );
      setCuentaConDataCenter(
        DatosInfaestructura.TieneDataCenter === null ||
          DatosInfaestructura.TieneDataCenter === false
          ? false
          : true
      );
      setEscalable(
        DatosInfaestructura.DCEscalable === null ||
          DatosInfaestructura.DCEscalable === false
          ? false
          : true
      );
      setAltaDisponibilidad(
        DatosInfaestructura.DCAltaDisponibilidad === null ||
          DatosInfaestructura.DCAltaDisponibilidad === false
          ? false
          : true
      );
      setSeguridad(
        DatosInfaestructura.DCSeguridad === null ||
          DatosInfaestructura.DCSeguridad === false
          ? false
          : true
      );
      setConectividadRobusta(
        DatosInfaestructura.DCRobusta === null ||
          DatosInfaestructura.DCRobusta === false
          ? false
          : true
      );
      setGestionCentralizada(
        DatosInfaestructura.DCGestionCentral === null ||
          DatosInfaestructura.DCGestionCentral === false
          ? false
          : true
      );
      setVirtualizacion(
        DatosInfaestructura.DCVirtualiza === null ||
          DatosInfaestructura.DCVirtualiza === false
          ? false
          : true
      );
      setRespaldoRecuperacion(
        DatosInfaestructura.DCRespaldo === null ||
          DatosInfaestructura.DCRespaldo === false
          ? false
          : true
      );
      setOtrasData(
        DatosInfaestructura.OtrasDC === null ? "" : DatosInfaestructura.OtrasDC
      );
      setCuentaConNube(
        DatosInfaestructura.TieneNube === null
          ? ""
          : DatosInfaestructura.TieneNube
      );
      setAmbientesSeparados(
        DatosInfaestructura.AmbientesSeparados === null
          ? ""
          : DatosInfaestructura.AmbientesSeparados
      );
      const DatosNube = await TraerServiciosNube(
        num,
        DatosSede.CodigoHabilitacion
      );
      setIaaS(
        DatosNube.IaaS === null || DatosNube.IaaS === false ? false : true
      );
      setPaaS(
        DatosNube.PaaS === null || DatosNube.PaaS === false ? false : true
      );
      setSaaS(
        DatosNube.SaaS === null || DatosNube.SaaS === false ? false : true
      );
      setAlmaNube(
        DatosNube.AlmacenamientoNube === null ||
          DatosNube.AlmacenamientoNube === false
          ? false
          : true
      );
      setCorreoNube(
        DatosNube.CorreoElectronico === null ||
          DatosNube.CorreoElectronico === false
          ? false
          : true
      );
      setOficinaNube(
        DatosNube.SoftwareOficina === null ||
          DatosNube.SoftwareOficina === false
          ? false
          : true
      );
      setDBNube(
        DatosNube.BDoArchivos === null || DatosNube.BDoArchivos === false
          ? false
          : true
      );
      setProcesamientoNube(
        DatosNube.Procesamiento === null || DatosNube.Procesamiento === false
          ? false
          : true
      );
      setOtrasNube(DatosNube.Otras === null ? "" : DatosNube.Otras);
      const DatosTalento = await TraerTalentoHumano(
        num,
        DatosSede.CodigoHabilitacion
      );
      setEquipoTIDedicado(
        DatosTalento.EquipoTIDedicado === null
          ? ""
          : DatosTalento.EquipoTIDedicado
      );
      setRolesEspecificos(
        DatosTalento.Roles === null ? "" : DatosTalento.Roles
      );
      setColaboracionTI(
        DatosTalento.Colaboracion === null ? "" : DatosTalento.Colaboracion
      );
      const DatosSeguridad = await TraerSeguridad(
        num,
        DatosSede.CodigoHabilitacion
      );
      setFirmaDigital(
        DatosSeguridad.FirmaDigital === null ||
          DatosSeguridad.FirmaDigital === false
          ? false
          : true
      );
      setArchivosCriptografiados(
        DatosSeguridad.Criptografia === null ||
          DatosSeguridad.Criptografia === false
          ? false
          : true
      );
      setProteccionContraseñaArchivos(
        DatosSeguridad.ContraArchivos === null ||
          DatosSeguridad.ContraArchivos === false
          ? false
          : true
      );
      setProteccionContraseñaSistema(
        DatosSeguridad.ContraAcceso === null ||
          DatosSeguridad.ContraAcceso === false
          ? false
          : true
      );
      setAntivirus(
        DatosSeguridad.Antivirus === null || DatosSeguridad.Antivirus === false
          ? false
          : true
      );
      setFirewallSE(
        DatosSeguridad.Firewall === null || DatosSeguridad.Firewall === false
          ? false
          : true
      );
      setCriptografiaBaseDatos(
        DatosSeguridad.BDCriptografia === null ||
          DatosSeguridad.BDCriptografia === false
          ? false
          : true
      );
      setCertificadoDigital(
        DatosSeguridad.CertifiDigital === null ||
          DatosSeguridad.CertifiDigital === false
          ? false
          : true
      );
      setAccesoBiometrico(
        DatosSeguridad.AccesoBiometrico === null ||
          DatosSeguridad.AccesoBiometrico === false
          ? false
          : true
      );
      setDobleFactor(
        DatosSeguridad.DobleFactor === null ||
          DatosSeguridad.DobleFactor === false
          ? false
          : true
      );
      setDlp(
        DatosSeguridad.DLP === null || DatosSeguridad.DLP === false
          ? false
          : true
      );
      setNinguna(
        DatosSeguridad.Ninguna === null || DatosSeguridad.Ninguna === false
          ? false
          : true
      );
      setIntegridadSeguridadDatos(
        DatosSeguridad.Medidas === null ? "" : DatosSeguridad.Medidas
      );
      setMonitoreoRegistroSeguridad(
        DatosSeguridad.Mecanismos === null ? "" : DatosSeguridad.Mecanismos
      );
      const DatosInfoCompartida = await TraerInfoCompartida(
        num,
        DatosSede.CodigoHabilitacion
      );
      setEstadoSalud(
        DatosInfoCompartida.EstadoSalud === null ||
          DatosInfoCompartida.EstadoSalud === false
          ? false
          : true
      );
      setSolicitudesAtencion(
        DatosInfoCompartida.SolicitudAtencion === null ||
          DatosInfoCompartida.SolicitudAtencion === false
          ? false
          : true
      );
      setResumenAtencion(
        DatosInfoCompartida.ResumenAtecion === null ||
          DatosInfoCompartida.ResumenAtecion === false
          ? false
          : true
      );
      setMedicamentosRecetados(
        DatosInfoCompartida.ListaRecetados === null ||
          DatosInfoCompartida.ListaRecetados === false
          ? false
          : true
      );
      setResultadosAnalisis(
        DatosInfoCompartida.ResultadosLab === null ||
          DatosInfoCompartida.ResultadosLab === false
          ? false
          : true
      );
      setResultadosImagenes(
        DatosInfoCompartida.ResultadosImg === null ||
          DatosInfoCompartida.ResultadosImg === false
          ? false
          : true
      );
      setPlanesCuidado(
        DatosInfoCompartida.PlanesCuidado === null ||
          DatosInfoCompartida.PlanesCuidado === false
          ? false
          : true
      );
      setNingunaInfo(
        DatosInfoCompartida.Ninguna === null ||
          DatosInfoCompartida.Ninguna === false
          ? false
          : true
      );
      setOtraInfo(
        DatosInfoCompartida.Otras === null ? "" : DatosInfoCompartida.Otras
      );
      const DatosHerramientas = await TraerHerramientas(
        num,
        DatosSede.CodigoHabilitacion
      );
      setPlataformasIntegracion(
        DatosHerramientas.IntegracionMiddleware === null ||
          DatosHerramientas.IntegracionMiddleware === false
          ? false
          : true
      );
      setSoftwarePropositoGeneral(
        DatosHerramientas.SoftwareGeneral === null ||
          DatosHerramientas.SoftwareGeneral === false
          ? false
          : true
      );
      setSinHerramientas(
        DatosHerramientas.NoHay === null || DatosHerramientas.NoHay === false
          ? false
          : true
      );
      setNoSabeHerra(
        DatosHerramientas.NoSabe === null || DatosHerramientas.NoSabe === false
          ? false
          : true
      );
      setOtrasHerra(
        DatosHerramientas.Otras === null ? "" : DatosHerramientas.Otras
      );
      const DatosEstandares = await TraerEstandares(
        num,
        DatosSede.CodigoHabilitacion
      );
      setEstrategia(
        DatosEstandares.EstrategiaDocumentada === null
          ? ""
          : DatosEstandares.EstrategiaDocumentada
      );
      setProcesoEstablecido(
        DatosEstandares.Procesos === null ? "" : DatosEstandares.Procesos
      );
      setMedidaEvaluativa(
        DatosEstandares.MedidasMonitoreo === null
          ? ""
          : DatosEstandares.MedidasMonitoreo
      );
      setEstandarProtocolo(
        DatosEstandares.EstandarInteroperabilidad === null
          ? ""
          : DatosEstandares.EstandarInteroperabilidad
      );
      setEstandarProtocoloOtro(
        DatosEstandares.OtroEstandar === null
          ? ""
          : DatosEstandares.OtroEstandar
      );
      if (DatosEstandares.EstandarInteroperabilidad === "OTRO") {
        setMostrarCampoOtropro(true);
      }
      setMecanismo(
        DatosEstandares.MecanismoMonitoreo === null
          ? ""
          : DatosEstandares.MecanismoMonitoreo
      );
      setGarantiza(
        DatosEstandares.Garantia === null ? "" : DatosEstandares.Garantia
      );
      setOtrasGarantiza(
        DatosEstandares.OtraGarantia === null
          ? ""
          : DatosEstandares.OtraGarantia
      );
      if (DatosEstandares.Garantia === "OTRAS") {
        setMostrarOtraGarantiza(true);
      }
      setFormato(DatosEstandares.Manejo === null ? "" : DatosEstandares.Manejo);
      setOtrasFormato(
        DatosEstandares.OtroManejo === null ? "" : DatosEstandares.OtroManejo
      );
      if (DatosEstandares.Manejo === "OTRAS") {
        setMostrarOtrasFormato(true);
      }
      setDiccionario(
        DatosEstandares.Diccionario === null ? "" : DatosEstandares.Diccionario
      );
      setOtrasDiccionario(
        DatosEstandares.OtroDiccionario === null
          ? ""
          : DatosEstandares.OtroDiccionario
      );
      if (DatosEstandares.Diccionario === "OTRAS") {
        setMostrarOtrasDiccionario(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`/sedes`, {
        params: {
          Sede: user,
        },
      });
      setDatos(response.data);
      const response2 = await axios.get("/sedes/ESE", {
        params: { ESE: user },
      });
      setESE(response2.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCancelar = async () => {
    window.location.reload();
  };

  const handleEditarESE = async () => {
    try {
      const Response = await axios.get("/DatosESE", {
        params: { ESE: user },
      });
      const DatosESE = Response.data[0];
      setNombreESE(
        DatosESE.NombrePrestador === null ? "" : DatosESE.NombrePrestador
      );
      setCodHabESE(
        DatosESE.CodHabilitacion === null ? "" : DatosESE.CodHabilitacion
      );
      setDireccionESE(DatosESE.Direccion === null ? "" : DatosESE.Direccion);
      setCorreoESE(DatosESE.Correo === null ? "" : DatosESE.Correo);
      setNumeroESE(DatosESE.Telefono === null ? "" : DatosESE.Telefono);
      setGerenteLegalESE(
        DatosESE.RepresentanteLegal === null ? "" : DatosESE.RepresentanteLegal
      );

      const Response2 = await axios.get("/DatosGerente", {
        params: { ESE: user },
      });
      const DatosGerente = Response2.data[0];

      setCorreoGerenteESE(
        DatosGerente.CorreoGerente === null ? "" : DatosGerente.CorreoGerente
      );
      setNumeroGerenteESE(
        DatosGerente.TelefonoGerente === null
          ? ""
          : DatosGerente.TelefonoGerente
      );
      setCargoGerenteESE(
        DatosGerente.CargoGerente === null ? "" : DatosGerente.CargoGerente
      );

      const Response3 = await axios.get("/DatosResponsable", {
        params: { ESE: user },
      });
      const DatosResponsable = Response3.data[0];

      setNombreResponsable(
        DatosResponsable.NombreResponsable === null
          ? ""
          : DatosResponsable.NombreResponsable
      );
      setCorreoRespon(
        DatosResponsable.CorreoResponsable === null
          ? ""
          : DatosResponsable.CorreoResponsable
      );
      setNumeroRes(
        DatosResponsable.TelefonoResponsable === null
          ? ""
          : DatosResponsable.TelefonoResponsable
      );
      setCargoRes(
        DatosResponsable.CargoResponsable === null
          ? ""
          : DatosResponsable.CargoResponsable
      );

      const Response4 = await axios.get("/DatosTecnicoGeneral", {
        params: { ESE: user },
      });
      const DatosTecnicoGeneral = Response4.data[0];

      setCuentaConTecnicoESE(
        DatosTecnicoGeneral.TieneTecnicoGeneralESE === null
          ? false
          : DatosTecnicoGeneral.TieneTecnicoGeneralESE
      );
      setNombreTecnicoESE(
        DatosTecnicoGeneral.NombreTecnicoGeneral === null
          ? ""
          : DatosTecnicoGeneral.NombreTecnicoGeneral
      );
      setNumeroTecnicoESE(
        DatosTecnicoGeneral.TelefonoTecnicoGeneral === null
          ? ""
          : DatosTecnicoGeneral.TelefonoTecnicoGeneral
      );
      setCargoTecnicoESE(
        DatosTecnicoGeneral.CargoTecnicoGeneral === null
          ? ""
          : DatosTecnicoGeneral.CargoTecnicoGeneral
      );
      setCorreoTecnicoESE(
        DatosTecnicoGeneral.CorreoTecnicoGeneral === null
          ? ""
          : DatosTecnicoGeneral.CorreoTecnicoGeneral
      );
    } catch (error) {
      console.log(error);
    }
  };

  function isValidDate(dateString) {
    var regEx = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateString.match(regEx)) return false; // Invalid format
    var d = new Date(dateString);
    var dNum = d.getTime();
    if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
    return d.toISOString().slice(0, 10) === dateString;
  }

  function containsOnlyDigits(str) {
    return /^\d+$/.test(str);
  }

  function isValidEmail(email) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }

  function validateMultipleEmails(emails) {
    // Separar los correos por comas, punto y coma, o espacios
    const separators = /[,\s;]+/;
    const emailArray = emails
      .split(separators)
      .filter((email) => !email.includes("-") && !email.includes("|"));
    // Validar cada correo electrónico individualmente
    for (let email of emailArray) {
      // Eliminar espacios al inicio y al final de cada correo
      email = email.trim();
      if (!isValidEmail(email)) {
        return false;
      }
    }
    return true;
  }

  const handleGuardarSede = async () => {
    if (SedeSeleccionada === "") {
      if (
        NombreSede === "" ||
        CodHabSede === "" ||
        DireccionSede === "" ||
        NumeroSede === "" ||
        CorreoSede === ""
      ) {
        toast.warning(
          `Algunos campos obligatorios están vacíos. Por favor, verificar y completar la información.`
        );
      } else if (NumeroNuevaSede === "") {
        toast.warning("Por favor, se debe indicar el número de la sede.");
      } else if (SedeNuevaHabilitada === "") {
        toast.warning(
          "Por favor, se debe indicar si la sede se encuentra habilitada."
        );
      } else if (NivelComplejidad === "") {
        toast.warning(
          "Por favor, se debe indicar el nivel de complejidad en atención en salud de la sede."
        );
      } else if (CodHabSede.length < 10) {
        toast.warning(
          "El código de habilitación de la sede debe tener 10 dígitos."
        );
      } else if (!containsOnlyDigits(CodHabSede)) {
        toast.warning(
          "El código de habilitación ingresado debe contener únicamente números."
        );
      } else if (!validateMultipleEmails(CorreoSede)) {
        toast.warning(
          "El correo electrónico de la sede no tiene un formato válido. Por favor, verificar y corregir la dirección de correo electrónico."
        );
      } else {
        try {
          const response = await axios.post("/InsertarNuevaSede", {
            CodigoPrestador: user,
            CodigoHabilitacion: CodHabSede.toUpperCase(),
            NumeroSede: NumeroNuevaSede.toUpperCase(),
            NombreSede: NombreSede.toUpperCase(),
            Direccion: DireccionSede.toUpperCase(),
            Telefono: NumeroSede.toUpperCase(),
            Correo: CorreoSede.toUpperCase(),
            Nivel: NivelComplejidad.toUpperCase(),
            Habilitado: SedeNuevaHabilitada.toUpperCase(),
          });
          if (response.status === 200) {
            toast.success(`La nueva sede se ha guardado correctamente.`);
          } else {
            toast.error(
              `Se ha producido un error al procesar los datos ingresados. Por favor, intentar de nuevo más tarde. Si el error persiste, ponerse en contacto con el administrador del sistema.`
            );
          }
        } catch (error) {
          toast.error(
            `Se ha producido un error al procesar los datos ingresados. Por favor, intentar de nuevo más tarde. Si el error persiste, ponerse en contacto con el administrador del sistema.`
          );
        }
      }
    } else {
      if (
        NombreSede === "" ||
        CodHabSede === "" ||
        DireccionSede === "" ||
        NumeroSede === "" ||
        CorreoSede === ""
      ) {
        toast.warning(
          `Algunos campos obligatorios en la información general están vacíos. Por favor, verificar la información proporcionada.`
        );
      } else if (NivelComplejidad === "") {
        toast.warning(
          "Por favor, se debe indicar el nivel de complejidad en atención en salud de la sede."
        );
      } else if (CodHabSede.length < 10) {
        toast.warning(
          "El código de habilitación de la sede debe tener 10 dígitos."
        );
      } else if (!containsOnlyDigits(CodHabSede)) {
        toast.warning(
          "El código de habilitación ingresado debe contener únicamente números."
        );
      } else if (CorreoSede !== "" && !validateMultipleEmails(CorreoSede)) {
        toast.warning(
          "El correo electrónico de la sede no tiene un formato válido. Por favor, verificar y corregir la dirección de correo electrónico."
        );
      } else if (
        CorreoTecnico !== "" &&
        !validateMultipleEmails(CorreoTecnico)
      ) {
        toast.warning(
          "El correo electrónico del contacto técnico no tiene un formato válido. Por favor, verificar y corregir la dirección de correo electrónico."
        );
      } else if (
        CorreoAdministrativo !== "" &&
        !validateMultipleEmails(CorreoAdministrativo)
      ) {
        toast.warning(
          "El correo electrónico del contacto administrativo no tiene un formato válido. Por favor, verificar y corregir la dirección de correo electrónico."
        );
      } else if (CorreoVPN !== "" && !validateMultipleEmails(CorreoVPN)) {
        toast.warning(
          "El correo electrónico del personal para VPN no tiene un formato válido. Por favor, verificar y corregir la dirección de correo electrónico."
        );
      } else if (
        CorreoContratistaVPN !== "" &&
        !validateMultipleEmails(CorreoContratistaVPN)
      ) {
        toast.warning(
          "El correo electrónico del contratista para VPN no tiene un formato válido. Por favor, verificar y corregir la dirección de correo electrónico."
        );
      } else if (
        CorreoComercial !== "" &&
        !validateMultipleEmails(CorreoComercial)
      ) {
        toast.warning(
          "El correo electrónico comercial del fabricante del software no tiene un formato válido. Por favor, verificar y corregir la dirección de correo electrónico."
        );
      } else if (
        CorreoAsistencia !== "" &&
        !validateMultipleEmails(CorreoAsistencia)
      ) {
        toast.warning(
          "El correo electrónico de asistencia técnica del software no tiene un formato válido. Por favor, verificar y corregir la dirección de correo electrónico."
        );
      } else if (
        CorreoPerSistema !== "" &&
        !validateMultipleEmails(CorreoPerSistema)
      ) {
        toast.warning(
          "El correo electrónico del referente o contacto para temas de software no tiene un formato válido. Por favor, verificar y corregir la dirección de correo electrónico."
        );
      } else if (FechaVence !== "" && !isValidDate(FechaVence)) {
        toast.warning(
          "La fecha de vencimiento del contrato de mantenimiento y soporte del software no tiene el formato correcto (aaaa-mm-dd). Por favor, verificar y corregir la fecha."
        );
      } else {
        try {
          const response = await axios.post("/ActualizarSede", {
            //General
            CodigoPrestador: user.substring(1),
            CodigoHabilitacion: CodHabSede.toUpperCase(),
            NumeroSede: NumSedeAEditar.toUpperCase(),
            DireccionSede: DireccionSede.toUpperCase(),
            TelefonoSede: NumeroSede.toUpperCase(),
            CorreoSede: CorreoSede.toUpperCase(),
            NivelSede: NivelComplejidad.toUpperCase(),
            //Coordenadas
            LatGrados: gradosLatitud.toUpperCase(),
            LatMinutos: minutosLatitud.toUpperCase(),
            LatSegundos: segundosLatitud.toUpperCase(),
            LatDecimal: latitudDecimal.toUpperCase(),
            LongGrados: gradosLongitud.toUpperCase(),
            LongMinutos: minutosLongitud.toUpperCase(),
            LongSegundos: segundosLongitud.toUpperCase(),
            LongDecimal: longitudDecimal.toUpperCase(),
            //PreguntasGenerales
            TieneTecnicoSede: CuentaConTecnico,
            TieneAdminSede: CuentaConAdministrativo,
            TieneConectividadSede: infraestructuraRed,
            TieneFirewallSede: firewall,
            TienePersonalVPNSede: PersonalVPN,
            TieneContratistaVPNSede: ContratistaVPN,
            TieneSoftwareSede: software,
            //DatosContactoTecnicoSede
            NameContactoT: NombreTecnico.toUpperCase(),
            CorreoContactoT: CorreoTecnico.toUpperCase(),
            TelefonoContactoT: NumeroTecnico.toUpperCase(),
            //DatosContactoAdministrativoSede
            NameContactoA: NombreAdministrativo.toUpperCase(),
            CorreoContactoA: CorreoAdministrativo.toUpperCase(),
            TelefonoContactoA: NumeroAdministrativo.toUpperCase(),
            Discado: ConexionDiscado,
            DSL: ConexionDSL,
            Cable: ConexionCable,
            Optica: ConexionFibraOptica,
            Satelital: ConexionSatelital,
            Radio: ConexionRadio,
            Movil: ConexionMovil,
            OtroTipoConexion: OtraConexion.toUpperCase(),
            Proveedor: proveedorConexion.toUpperCase(),
            OtroProveedor: proveedorOtro.toUpperCase(),
            Velocidad: anchoBanda.toUpperCase(),
            FabricanteFirewall: FabricanteFirewall.toUpperCase(),
            ModeloFirewall: ModeloFirewall.toUpperCase(),
            FirewallActualizado: UltimaVersionFirewall,
            VersionF: versionFirewall.toUpperCase(),
            NameVPN: NombreVPN.toUpperCase(),
            CorreoVPN: CorreoVPN.toUpperCase(),
            TelefonoVPN: NumeroVPN.toUpperCase(),
            NameConVPN: NombreContratistaVPN.toUpperCase(),
            CorreoConVPN: CorreoContratistaVPN.toUpperCase(),
            TelefonoConVPN: NumeroContratistaVPN.toUpperCase(),
            NameSis: NombreSoftware.toUpperCase(),
            NameProveeSis: FabricanteSoftware.toUpperCase(),
            ModalidadSis: Modalidad.toUpperCase(),
            LicenciaSis: TipoLicencia.toUpperCase(),
            ComercialPro: CorreoComercial.toUpperCase(),
            Asistenciapro: CorreoAsistencia.toUpperCase(),
            NumComerPro: NumeroComercial.toUpperCase(),
            NumAsisPro: NumeroAsistencia.toUpperCase(),
            NameRefe: NombrePerSistema.toUpperCase(),
            CorreoRefe: CorreoPerSistema.toUpperCase(),
            TelefonoRefe: NumeroPerSistema.toUpperCase(),
            CargoRefe: CargoPerSistema.toUpperCase(),
            InfoDemo: InfoDemo,
            Diagnosticos: Diagnosticos,
            Antece: Antecedentes,
            Alergias: Alergias,
            SignosVi: SignosVitales,
            Medicamentos: Medicamentos,
            Vacunas: Vacunas,
            Notas: NotasEnfermeria,
            Resuls: Resultados,
            Informes: InformesRadiologicos,
            Images: ImagesRadiologicos,
            Detalles: Detalles,
            Previos: RegistrosPrevios,
            OtraIncluida: OtrosRegistros.toUpperCase(),
            SisActualizado: UltimaVersionSoftware,
            VersionSis: versionSoftware.toUpperCase(),
            ContratoSis: ContratoSoftware,
            FechaSis: FechaVence,
            TieneDC: CuentaConDataCenter,
            Escalable: Escalable,
            AltaD: AltaDisponibilidad,
            SeguridadD: Seguridad,
            RobustaD: ConectividadRobusta,
            GCentralD: GestionCentralizada,
            Virtualiza: Virtualizacion,
            Respaldo: RespaldoRecuperacion,
            OtrasDC: OtrasData.toUpperCase(),
            Nube: CuentaConNube,
            IaaS: IaaS,
            PaaS: PaaS,
            SaaS: SaaS,
            AlmaNube: AlmaNube,
            CorreoNube: CorreoNube,
            OficinaNube: OficinaNube,
            bdNube: DBNube,
            ProcesamientoNube: ProcesamientoNube,
            OtrasNube: OtrasNube.toUpperCase(),
            Separado: AmbientesSeparados,
            Dedicado: equipoTIDedicado,
            Roles: RolesEspecificos,
            Colabora: colaboracionTI,
            FirmaDigital: firmaDigital,
            Criptografiados: archivosCriptografiados,
            ContraArchivos: proteccionContraseñaArchivos,
            ContraSistema: proteccionContraseñaSistema,
            Antivirus: antivirus,
            firewallSE: firewallSE,
            BDCripto: criptografiaBaseDatos,
            Cert: certificadoDigital,
            Biometria: accesoBiometrico,
            DobleFactor: dobleFactor,
            DLP: dlp,
            NingunaSe: ninguna,
            Medidas: integridadSeguridadDatos,
            Monitoreo: monitoreoRegistroSeguridad,
            EstadoSalud: estadoSalud,
            Solicitudes: solicitudesAtencion,
            Resumen: resumenAtencion,
            Lista: medicamentosRecetados,
            ResultadosLab: resultadosAnalisis,
            Resultadosimgs: resultadosImagenes,
            Planes: planesCuidado,
            ningunaInfo: ningunaInfo,
            OtraInfo: OtraInfo.toUpperCase(),
            Integracion: plataformasIntegracion,
            SisGeneral: softwarePropositoGeneral,
            NoHerra: sinHerramientas,
            NoSabeHerra: noSabeHerra,
            OtraHerra: OtrasHerra.toUpperCase(),
            Estrategia: Estrategia,
            Proceso: ProcesoEstablecido,
            Medida: MedidaEvaluativa,
            Estandares: EstandarProtocolo,
            OtroEstandar: EstandarProtocoloOtro.toUpperCase(),
            Mecanismo: Mecanismo,
            Garantiza: Garantiza,
            OtrasGarantiza: OtrasGarantiza.toUpperCase(),
            Formato: Formato,
            OtroFormato: OtrasFormato.toUpperCase(),
            Diccionario: Diccionario,
            OtroDiccionario: OtrasDiccionario.toUpperCase(),
          });
          if (response.status === 200) {
            toast.success(`${response.data.message}`);
          } else {
            toast.error(`${response.data.message}`);
          }
        } catch (error) {
          toast.error(
            `Se ha producido un error al procesar los datos ingresados. Por favor, intentar de nuevo más tarde. Si el error persiste, ponerse en contacto con el administrador del sistema.`
          );
        }
      }
    }
  };
  const handleGuardarESE = async () => {
    if (
      NombreESE === "" ||
      CodHabESE === "" ||
      DireccionESE === "" ||
      CorreoESE === "" ||
      NumeroESE === "" ||
      GerenteLegalESE === "" ||
      CorreoGerenteESE === "" ||
      NumeroGerenteESE === "" ||
      CargoGerenteESE === "" ||
      NombreResponsable === "" ||
      CorreoRespon === "" ||
      NumeroRes === "" ||
      CargoRes === ""
    ) {
      toast.warning(
        `Algunos campos obligatorios están vacíos. Por favor, verificar.`
      );
    } else if (!validateMultipleEmails(CorreoESE)) {
      toast.warning(
        "El correo electrónico de la institución no tiene un formato válido. Por favor, verificar y asegurarse de ingresar una dirección de correo electrónico válida."
      );
    } else if (
      CorreoGerenteESE !== "" &&
      !validateMultipleEmails(CorreoGerenteESE)
    ) {
      toast.warning(
        "El correo electrónico del gerente o representante legal no tiene un formato válido. Por favor, asegurarse de ingresar una dirección de correo electrónico válida.",
        CorreoGerenteESE
      );
    } else if (CorreoRespon !== "" && !validateMultipleEmails(CorreoRespon)) {
      toast.warning(
        "El correo electrónico del responsable que diligencia no tiene un formato válido. Por favor, asegurarse de ingresar una dirección de correo electrónico válida."
      );
    } else if (
      CorreoTecnicoESE !== "" &&
      !validateMultipleEmails(CorreoTecnicoESE)
    ) {
      toast.warning(
        "El correo electrónico del técnico general de la institución no tiene un formato válido. Por favor, verificar y asegurarse de ingresar una dirección de correo electrónico válida."
      );
    } else {
      try {
        const response = await axios.post("/ActualizaESE", {
          NombrePrestador: NombreESE.toUpperCase(),
          CodHabilitacion: user,
          Direccion: DireccionESE.toUpperCase(),
          Correo: CorreoESE.toUpperCase(),
          Telefono: NumeroESE.toUpperCase(),
          RepresentanteLegal: GerenteLegalESE.toUpperCase(),
          CorreoGerente: CorreoGerenteESE.toUpperCase(),
          TelefonoGerente: NumeroGerenteESE.toUpperCase(),
          CargoGerente: CargoGerenteESE.toUpperCase(),
          NombreResponsable: NombreResponsable.toUpperCase(),
          CorreoResponsable: CorreoRespon.toUpperCase(),
          TelefonoResponsable: NumeroRes.toUpperCase(),
          CargoResponsable: CargoRes.toUpperCase(),
          NombreTecnicoGeneral: NombreTecnicoESE.toUpperCase(),
          CorreoTecnicoGeneral: CorreoTecnicoESE.toUpperCase(),
          TelefonoTecnicoGeneral: NumeroTecnicoESE.toUpperCase(),
          CargoTecnicoGeneral: CargoTecnicoESE.toUpperCase(),
          TieneTecnicoGeneralESE: CuentaConTecnicoESE,
        });
        if (response.status === 200) {
          toast.success(`Los datos se han actualizado correctamente.`);
        } else {
          toast.error(
            `Se ha producido un error al procesar los datos ingresados. Por favor, intentar de nuevo más tarde. Si el error persiste, contactar al administrador del sistema.`
          );
        }
      } catch (error) {
        toast.error(
          `Se ha producido un error al procesar los datos ingresados. Por favor, intentar de nuevo más tarde. Si el error persiste, contactar al administrador del sistema.`
        );
      }
    }
  };

  return (
    <div className="d-flex flex-column align-items-center vh-100 text-light">
      <div className="d-flex justify-content-center">
        <img src={require("../Imgs/logo.png")} alt="Logo" className="w-25" />
      </div>
      {isLoading ? ( // Si isLoading es true, mostrar el spinner
        <div className="d-flex align-items-center justify-content-center vh-100">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Cargando...</span>
          </div>
        </div>
      ) : (
        <div
          className="form-group text-center w-75 p-3"
          autoComplete="off"
          style={{ borderRadius: "20px", background: "#326939" }}
        >
          <h1>Institución Prestadora de Servicios de Salud</h1>
          <h5>{ESE && ESE[0].FechaREPS}</h5>
          <table className="table table-striped table-hover my-1 shadow-lg">
            <thead>
              <tr className="bg-success">
                <th scope="col">Código de habilitación</th>
                <th scope="col">Municipio</th>
                <th scope="col">ESE</th>
                <th scope="col">Opciones</th>
              </tr>
            </thead>
            <tbody>
              {ESE &&
                ESE.map((dato, index) => (
                  <tr key={index}>
                    <td>{dato.CodHabilitacion}</td>
                    <td>{dato.NombreMunicipio}</td>
                    <td>{dato.NombrePrestador}</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-success ms-5"
                        data-bs-toggle="modal"
                        data-bs-target="#FormESE"
                        onClick={() => {
                          setESESeleccionada(dato.NombrePrestador);
                          handleEditarESE();
                        }}
                      >
                        Actualizar datos
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <h1>Sedes</h1>
          <h5>{datos && datos[0].FechaREPS}</h5>
          <table className="table table-striped table-hover my-1 shadow-lg">
            <thead>
              <tr className="bg-success">
                <th scope="col">Código de Habilitación</th>
                <th scope="col">Sede #</th>
                <th scope="col">Municipio</th>
                <th scope="col">Nombre</th>
                <th scope="col">Opciones</th>
              </tr>
            </thead>
            <tbody>
              {datos &&
                datos.map((dato, index) => (
                  <tr key={index}>
                    <td>{dato.CodigoHabilitacion}</td>
                    <td>
                      {dato.SedePrincipal === true
                        ? `Sede Principal (${dato.NumeroSede})`
                        : dato.NumeroSede}
                    </td>
                    <td>{dato.NombreMunicipio}</td>
                    <td>{dato.NombreSede}</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-success"
                        data-bs-toggle="modal"
                        data-bs-target="#MensajeConfirmacion"
                        onClick={() => {
                          setNumSedeAEditar(dato.NumeroSede);
                          setSedeSeleccionada(dato.NombreSede);
                          handleEditarSede(dato.NumeroSede);
                        }}
                      >
                        Actualizar datos
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <button
            className="btn mt-1 mx-3 btn-outline-warning"
            onClick={handleLogout}
          >
            Cerrar Sesión
          </button>
          <button
            onClick={() => {
              toast.info(
                "Por favor, tener en cuenta que al agregar una nueva sede en esta plataforma, la información no se actualizará automáticamente en el REPS. Para oficializar la nueva sede, es necesario completar el trámite correspondiente en el REPS."
              );
              setSedeSeleccionada("");
              setBloqueo(true);
            }}
            type="button"
            className="btn mt-1 mx-3 btn-success"
            data-bs-toggle="modal"
            data-bs-target="#MensajeConfirmacion"
          >
            Añadir Sede
          </button>
          <div
            className="modal fade"
            id="FormESE"
            tabIndex="-1"
            aria-labelledby="FormESELabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="modal-title text-dark" id="exampleModalLabel">
                    Editando ESE: {ESESeleccionada}
                  </h3>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={handleCancelar}
                  ></button>
                </div>
                <div className="modal-body d-flex flex-column  justify-content-around bg-secondary-subtle text-secondary-emphasis">
                  <h5 className="text-start fs-6 mb-2 w-75">
                    Por favor, tener en cuenta lo siguiente:
                  </h5>
                  <p className="text-start fs-6 mb-1 w-75 ms-5">
                    - La información que se diligencia a continuación se
                    actualizará en tiempo real en la base de datos de la
                    Secretaría Seccional de Salud y Protección Social de
                    Antioquia al hacer clic en guardar.
                  </p>
                  <p className="text-start fs-6 mb-1 w-75 ms-5">
                    - Los campos marcados con * son obligatorios.
                  </p>
                  <p className="text-start fs-6 mb-1 w-75 ms-5">
                    - La información proviene del REPS (Registro Especial de
                    Prestadores de Servicios de Salud); en caso de encontrar un
                    error, verificar allí.
                  </p>
                  <p className="text-start fs-6 mb-1 w-75 ms-5">
                    - Tener en cuenta que la información actualizada en esta
                    plataforma no se reflejará en el REPS.
                  </p>
                  <div className="accordion my-3 w-100" id="accordionExample2">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingGeneral">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseGeneralESE"
                          aria-expanded="false"
                          aria-controls="collapseGeneralESE"
                        >
                          Información General
                        </button>
                      </h2>
                      <div
                        id="collapseGeneralESE"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingGeneralESE"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body d-flex flex-column">
                          <div className="form-floating mb-3">
                            <input
                              id="NombreESE"
                              placeholder="Digite el nombre:"
                              className="form-control"
                              type="text"
                              maxLength={250}
                              value={NombreESE}
                              onChange={(e) => setNombreESE(e.target.value)}
                              disabled={true}
                            />
                            <label htmlFor="NombreESE">
                              Nombre de la Institución:*
                            </label>
                          </div>
                          <div className="form-floating mb-3">
                            <input
                              id="CodHabESE"
                              placeholder="Digite el código de habilitación:"
                              className="form-control"
                              type="text"
                              maxLength={10}
                              value={CodHabESE}
                              onChange={(e) => setCodHabESE(e.target.value)}
                              disabled={true}
                            />
                            <label htmlFor="CodHabESE">
                              Código de habilitación:*
                            </label>
                          </div>
                          <div className="form-floating mb-3">
                            <input
                              id="DireccionESE"
                              placeholder="Digite la dirección: "
                              className="form-control"
                              type="text"
                              maxLength={250}
                              value={DireccionESE}
                              onChange={(e) => setDireccionESE(e.target.value)}
                            />
                            <label htmlFor="DireccionESE">
                              Dirección de la Institución:*
                            </label>
                          </div>
                          <div className="form-floating mb-3">
                            <input
                              id="CorreoESE"
                              placeholder="Digite el correo:"
                              className="form-control"
                              type="text"
                              maxLength={150}
                              value={CorreoESE}
                              onChange={(e) => setCorreoESE(e.target.value)}
                            />
                            <label htmlFor="CorreoESE">
                              Correo de la Institución:*
                            </label>
                          </div>
                          <div className="form-floating mb-3">
                            <input
                              id="NumeroESE"
                              placeholder="Digite el número: "
                              className="form-control"
                              type="text"
                              maxLength={100}
                              value={NumeroESE}
                              onChange={(e) => setNumeroESE(e.target.value)}
                            />
                            <label htmlFor="NumeroESE">
                              Número de teléfono móvil o fíjo de la
                              Institución:*
                            </label>
                          </div>
                          <div className="form-floating mb-3">
                            <input
                              id="GerenteLegalESE"
                              placeholder="Digite el nombre del gerente:"
                              className="form-control"
                              type="text"
                              maxLength={150}
                              value={GerenteLegalESE}
                              onChange={(e) =>
                                setGerenteLegalESE(e.target.value)
                              }
                            />
                            <label htmlFor="GerenteLegalESE">
                              Nombre completo del Gerente o Representante Legal
                              de la Institución:*
                            </label>
                          </div>
                          <div className="form-floating mb-3">
                            <input
                              id="CorreoGerenteESE"
                              placeholder="Digite el correo"
                              className="form-control"
                              type="text"
                              maxLength={150}
                              value={CorreoGerenteESE}
                              onChange={(e) =>
                                setCorreoGerenteESE(e.target.value)
                              }
                            />
                            <label htmlFor="CorreoGerenteESE">
                              Correo electrónico del Gerente o Representante
                              Legal:*
                            </label>
                          </div>
                          <div className="form-floating mb-3">
                            <input
                              id="NumeroGerenteESE"
                              placeholder="Digite el número: "
                              className="form-control"
                              type="text"
                              maxLength={150}
                              value={NumeroGerenteESE}
                              onChange={(e) =>
                                setNumeroGerenteESE(e.target.value)
                              }
                            />
                            <label htmlFor="NumeroGerenteESE">
                              Número de teléfono de contacto del gerente o
                              Representante legal:*
                            </label>
                          </div>
                          <div className="form-floating mb-3">
                            <input
                              id="CargoGerenteESE"
                              placeholder="Digite el cargo: "
                              className="form-control"
                              type="text"
                              maxLength={100}
                              value={CargoGerenteESE}
                              onChange={(e) =>
                                setCargoGerenteESE(e.target.value)
                              }
                            />
                            <label htmlFor="CargoGerenteESE">Cargo:*</label>
                          </div>
                          <div className="form-floating mb-3">
                            <input
                              id="NombreResponsable"
                              placeholder="Digite el Nombre: "
                              className="form-control"
                              type="text"
                              maxLength={100}
                              value={NombreResponsable}
                              onChange={(e) =>
                                setNombreResponsable(e.target.value)
                              }
                            />
                            <label htmlFor="NombreResponsable">
                              Nombre de la persona responsable que diligencia la
                              encuesta:*
                            </label>
                          </div>
                          <div className="form-floating mb-3">
                            <input
                              id="CorreoResponsable"
                              placeholder="Digite el correo: "
                              className="form-control"
                              type="text"
                              maxLength={100}
                              value={CorreoRespon}
                              onChange={(e) => setCorreoRespon(e.target.value)}
                            />
                            <label htmlFor="CorreoResponsable">
                              Correo electrónico del responsable que diligencia
                              la encuesta:*
                            </label>
                          </div>
                          <div className="form-floating mb-3">
                            <input
                              id="NumeroRes"
                              placeholder="Digite el número: "
                              className="form-control"
                              type="text"
                              maxLength={100}
                              value={NumeroRes}
                              onChange={(e) => setNumeroRes(e.target.value)}
                            />
                            <label htmlFor="NumeroRes">
                              Numero de contacto del responsable que
                              diligencia:*
                            </label>
                          </div>
                          <div className="form-floating mb-3">
                            <input
                              id="CargoRes"
                              placeholder="Digite el cargo: "
                              className="form-control"
                              type="text"
                              maxLength={100}
                              value={CargoRes}
                              onChange={(e) => setCargoRes(e.target.value)}
                            />
                            <label htmlFor="CargoRes">
                              Cargo del responsable que diligencia:*
                            </label>
                          </div>
                          <div className="form-check form-switch text-start">
                            <label
                              className="form-check-label"
                              htmlFor="CuentaConTecnicoESE"
                            >
                              La Institución cuenta con un contacto técnico
                              general?
                            </label>
                            <input
                              id="CuentaConTecnicoESE"
                              className="form-check-input"
                              type="checkbox"
                              checked={CuentaConTecnicoESE}
                              onChange={(e) =>
                                setCuentaConTecnicoESE(e.target.checked)
                              }
                            />
                          </div>
                          {CuentaConTecnicoESE && (
                            <>
                              <div className="form-floating mb-3">
                                <input
                                  id="NombreTecnicoESE"
                                  placeholder="Digite el nombre:"
                                  className="form-control"
                                  type="text"
                                  maxLength={100}
                                  value={NombreTecnicoESE}
                                  onChange={(e) =>
                                    setNombreTecnicoESE(e.target.value)
                                  }
                                />
                                <label htmlFor="NombreTecnico">
                                  Nombre completo del contacto técnico
                                </label>
                              </div>
                              <div className="form-floating mb-3">
                                <input
                                  id="CorreoTecnicoESE"
                                  placeholder="Ejemplo@correo.com"
                                  className="form-control"
                                  type="text"
                                  maxLength={100}
                                  value={CorreoTecnicoESE}
                                  onChange={(e) =>
                                    setCorreoTecnicoESE(e.target.value)
                                  }
                                />
                                <label htmlFor="CorreoTecnicoESE">
                                  Correo electrónico del contacto técnico:
                                </label>
                              </div>
                              <div className="form-floating mb-3">
                                <input
                                  id="NumeroTecnicoESE"
                                  type="text"
                                  maxLength={35}
                                  placeholder="En caso de ser varios, separar con un guión (-)"
                                  className="form-control"
                                  value={NumeroTecnicoESE}
                                  onChange={(e) =>
                                    setNumeroTecnicoESE(e.target.value)
                                  }
                                />
                                <label htmlFor="NumeroTecnicoESE">
                                  Número de teléfono móvil o fíjo del contacto
                                  técnico:
                                </label>
                              </div>
                              <div className="form-floating mb-3">
                                <input
                                  id="CargoTecnicoESE"
                                  type="text"
                                  maxLength={35}
                                  placeholder="Ingrese el cargo:"
                                  className="form-control"
                                  value={CargoTecnicoESE}
                                  onChange={(e) =>
                                    setCargoTecnicoESE(e.target.value)
                                  }
                                />
                                <label htmlFor="CargoTecnicoESE">
                                  Cargo del contacto técnico:
                                </label>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={handleCancelar}
                  >
                    Cancelar
                  </button>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={handleGuardarESE}
                  >
                    Guardar cambios
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="MensajeConfirmacion"
            tabIndex="-1"
            aria-labelledby="MensajeConfirmacionLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="modal-title text-dark" id="exampleModalLabel">
                    {SedeSeleccionada === "" ? (
                      <>Insertando nueva sede:</>
                    ) : (
                      <>Modificando sede: {SedeSeleccionada}</>
                    )}
                  </h3>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={handleCancelar}
                  ></button>
                </div>
                <div className="modal-body d-flex flex-column justify-content-around bg-secondary-subtle text-secondary-emphasis">
                  <h5 className="text-start fs-6 mb-2 w-75">
                    Por favor, tener en cuenta lo siguiente:
                  </h5>
                  <p className="text-start fs-6 mb-1 w-75 ms-5">
                    - La información que se diligencia a continuación se
                    actualizará en tiempo real en la base de datos de la
                    Secretaría Seccional de Salud y Protección Social de
                    Antioquia al hacer clic en guardar.
                  </p>
                  <p className="text-start fs-6 mb-1 w-75 ms-5">
                    - Los campos marcados con * son obligatorios.
                  </p>
                  <p className="text-start fs-6 mb-1 w-75 ms-5">
                    - La información proviene del REPS (Registro Especial de
                    Prestadores de Servicios de Salud); en caso de encontrar un
                    error, verificar allí.
                  </p>
                  <p className="text-start fs-6 mb-1 w-75 ms-5">
                    - Tener en cuenta que la información actualizada en esta
                    plataforma no se reflejará en el REPS.
                  </p>
                  <div className="accordion my-3 w-100" id="accordionExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingGeneral">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseGeneral"
                          aria-expanded="false"
                          aria-controls="collapseGeneral"
                        >
                          Información General
                        </button>
                      </h2>
                      <div
                        id="collapseGeneral"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingGeneral"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body d-flex flex-column">
                          <div className="form-floating mb-3">
                            <input
                              id="NombreSede"
                              placeholder="Digite el nombre:"
                              className="form-control"
                              type="text"
                              maxLength={250}
                              value={NombreSede}
                              onChange={(e) => setNombreSede(e.target.value)}
                              disabled={!Bloqueo}
                            />
                            <label htmlFor="NombreSede">
                              Nombre completo de la sede:*
                            </label>
                          </div>
                          {SedeSeleccionada === "" && (
                            <>
                              <div className="input-group mb-3">
                                <label
                                  className="input-group-text"
                                  htmlFor="NumeroNuevaSede"
                                >
                                  Seleccione el número de la sede a ingresar:*
                                </label>
                                {datos && (
                                  <select
                                    className="form-select"
                                    id="NumeroNuevaSede"
                                    value={NumeroNuevaSede}
                                    onChange={(e) => {
                                      setNumeroNuevaSede(e.target.value);
                                    }}
                                  >
                                    <option value="">
                                      Seleccione una opción
                                    </option>
                                    {numerosSedesDisponibles
                                      .slice(0, 10)
                                      .map((numeroSede) => (
                                        <option
                                          key={numeroSede}
                                          value={numeroSede}
                                        >
                                          {numeroSede}
                                        </option>
                                      ))}
                                  </select>
                                )}
                              </div>
                              <div className="input-group mb-3">
                                <label
                                  className="input-group-text"
                                  htmlFor="SedeHabilitada"
                                >
                                  La sede se encuentra habilitada?*
                                </label>
                                <select
                                  className="form-select "
                                  id="SedeHabilitada"
                                  value={SedeNuevaHabilitada}
                                  onChange={(e) =>
                                    setSedeNuevaHabilitada(e.target.value)
                                  }
                                >
                                  <option value="">
                                    Seleccione una opción
                                  </option>
                                  <option value="1">SI</option>
                                  <option value="0">NO</option>
                                </select>
                              </div>
                            </>
                          )}
                          <div className="form-floating mb-3">
                            <input
                              id="CodHabSede"
                              placeholder="Digite el código de habilitación:"
                              className="form-control"
                              type="text"
                              maxLength={10}
                              value={CodHabSede}
                              onChange={(e) => setCodHabSede(e.target.value)}
                              disabled={!Bloqueo}
                            />
                            <label htmlFor="CodHabSede">
                              Código de habilitación de la sede:*
                            </label>
                          </div>
                          <div className="form-floating mb-3">
                            <input
                              id="DireccionSede"
                              placeholder="Digite la dirección:"
                              className="form-control"
                              type="text"
                              maxLength={255}
                              value={DireccionSede}
                              onChange={(e) => setDireccionSede(e.target.value)}
                            />
                            <label htmlFor="DireccionSede">
                              Dirección actual de la sede:*
                            </label>
                          </div>
                          <>
                            <div className="form-floating mb-3">
                              <input
                                id="NumeroSede"
                                type="text"
                                maxLength={150}
                                placeholder="En caso de ser varios, separar con un guión (-)"
                                className="form-control"
                                value={NumeroSede}
                                onChange={(e) => setNumeroSede(e.target.value)}
                              />
                              <label htmlFor="NumeroSede">
                                Número de teléfono móvil o fíjo de la sede:*
                              </label>
                            </div>
                            <div className="form-floating mb-3">
                              <input
                                id="CorreoSede"
                                placeholder="Ejemplo@correo.com"
                                className="form-control"
                                type="text"
                                value={CorreoSede}
                                maxLength={255}
                                onChange={(e) => setCorreoSede(e.target.value)}
                              />
                              <label htmlFor="CorreoSede">
                                Correo electrónico de la sede:*
                              </label>
                            </div>
                            <div className="input-group mb-3">
                              <label
                                className="input-group-text"
                                htmlFor="NivelComplejidad"
                              >
                                Nivel de complejidad en atención en salud de la
                                sede:*
                              </label>
                              <select
                                className="form-select "
                                id="NivelComplejidad"
                                value={NivelComplejidad}
                                onChange={(e) =>
                                  setNivelComplejidad(e.target.value)
                                }
                              >
                                <option value="">Seleccione una opción</option>
                                <option value="1">Nivel 1</option>
                                <option value="2">Nivel 2</option>
                                <option value="3">Nivel 3</option>
                                <option value="4">Nivel 4</option>
                              </select>
                            </div>
                          </>
                          {SedeSeleccionada !== "" && (
                            <>
                              <h5 className="text-start">
                                coordenadas geográficas en sistema sexagesimal
                              </h5>
                              <div className="input-group mb-1">
                                <span className="input-group-text mb-1">
                                  Latitud de la sede
                                </span>
                                <div className="form-floating mb-1">
                                  <input
                                    id="GradosLatitud"
                                    type="text"
                                    className="form-control"
                                    placeholder="Grados °"
                                    aria-label="GradosLatitud"
                                    maxLength={5}
                                    value={gradosLatitud}
                                    onChange={(e) =>
                                      setGradosLatitud(e.target.value)
                                    }
                                  />
                                  <label htmlFor="GradosLatitud">
                                    Grados °
                                  </label>
                                </div>
                                <div className="form-floating mb-1">
                                  <input
                                    id="MinutosLatitud"
                                    type="text"
                                    className="form-control"
                                    placeholder="Minutos '"
                                    aria-label="MinutosLatitud"
                                    maxLength={5}
                                    value={minutosLatitud}
                                    onChange={(e) =>
                                      setMinutosLatitud(e.target.value)
                                    }
                                  />
                                  <label htmlFor="MinutosLatitud">
                                    Minutos '
                                  </label>
                                </div>
                                <div className="form-floating mb-1">
                                  <input
                                    id="SegundosLatitud"
                                    type="text"
                                    className="form-control"
                                    placeholder="Segundos ''"
                                    aria-label="SegundosLatitud"
                                    maxLength={5}
                                    value={segundosLatitud}
                                    onChange={(e) =>
                                      setSegundosLatitud(e.target.value)
                                    }
                                  />
                                  <label htmlFor="SegundosLatitud">
                                    Segundos ''
                                  </label>
                                </div>
                              </div>
                              <div className="input-group mb-2">
                                <span className="input-group-text mb-2">
                                  Longitud de la sede
                                </span>
                                <div className="form-floating mb-2">
                                  <input
                                    id="GradosLongitud"
                                    type="text"
                                    className="form-control"
                                    placeholder="Grados °"
                                    aria-label="GradosLongitud"
                                    maxLength={5}
                                    value={gradosLongitud}
                                    onChange={(e) =>
                                      setGradosLongitud(e.target.value)
                                    }
                                  />
                                  <label htmlFor="GradosLongitud">
                                    Grados °
                                  </label>
                                </div>
                                <div className="form-floating mb-2">
                                  <input
                                    id="MinutosLongitud"
                                    type="text"
                                    className="form-control"
                                    placeholder="Minutos '"
                                    aria-label="MinutosLongitud"
                                    maxLength={5}
                                    value={minutosLongitud}
                                    onChange={(e) =>
                                      setMinutosLongitud(e.target.value)
                                    }
                                  />
                                  <label htmlFor="MinutosLongitud">
                                    Minutos '
                                  </label>
                                </div>
                                <div className="form-floating mb-2">
                                  <input
                                    id="SegundosLongitud"
                                    type="text"
                                    className="form-control"
                                    placeholder="Segundos ''"
                                    aria-label="SegundosLongitud"
                                    maxLength={5}
                                    value={segundosLongitud}
                                    onChange={(e) =>
                                      setSegundosLongitud(e.target.value)
                                    }
                                  />
                                  <label htmlFor="SegundosLongitud">
                                    Segundos ''
                                  </label>
                                </div>
                              </div>
                              <h5 className="text-start">
                                coordenadas geográficas en sistema decimal
                                (opcional)
                              </h5>
                              <div className="form-floating mb-2">
                                <input
                                  id="LongitudDecimal"
                                  type="text"
                                  className="form-control"
                                  placeholder="Longitud en decimal"
                                  aria-label="LongitudDecimal"
                                  maxLength={50}
                                  value={longitudDecimal}
                                  onChange={(e) =>
                                    setLongitudDecimal(e.target.value)
                                  }
                                />
                                <label htmlFor="LongitudDecimal">
                                  Longitud en decimal
                                </label>
                              </div>
                              <div className="form-floating mb-3">
                                <input
                                  id="LatitudDecimal"
                                  type="text"
                                  className="form-control"
                                  placeholder="Latitud en decimal"
                                  aria-label="LatitudDecimal"
                                  maxLength={50}
                                  value={latitudDecimal}
                                  onChange={(e) =>
                                    setLatitudDecimal(e.target.value)
                                  }
                                />
                                <label htmlFor="LatitudDecimal">
                                  Latitud en decimal
                                </label>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    {SedeSeleccionada !== "" && (
                      <>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingContacto">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseContacto"
                              aria-expanded="false"
                              aria-controls="collapseContacto"
                            >
                              Información de contacto
                            </button>
                          </h2>
                          <div
                            id="collapseContacto"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingContacto"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body d-flex flex-column">
                              <div className="form-check form-switch text-start">
                                <label
                                  className="form-check-label"
                                  htmlFor="CuentaConTecnico"
                                >
                                  La sede cuenta con un contacto técnico?
                                </label>
                                <input
                                  id="CuentaConTecnico"
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={CuentaConTecnico}
                                  onChange={(e) =>
                                    setCuentaConTecnico(e.target.checked)
                                  }
                                />
                              </div>
                              {CuentaConTecnico && (
                                <>
                                  <div className="form-floating mb-3">
                                    <input
                                      id="NombreTecnico"
                                      placeholder="Digite el nombre:"
                                      className="form-control"
                                      type="text"
                                      maxLength={150}
                                      value={NombreTecnico}
                                      onChange={(e) =>
                                        setNombreTecnico(e.target.value)
                                      }
                                    />
                                    <label htmlFor="NombreTecnico">
                                      Nombre completo del contacto técnico
                                    </label>
                                  </div>
                                  <div className="form-floating mb-3">
                                    <input
                                      id="CorreoTecnico"
                                      placeholder="Ejemplo@correo.com"
                                      className="form-control"
                                      type="text"
                                      maxLength={150}
                                      value={CorreoTecnico}
                                      onChange={(e) =>
                                        setCorreoTecnico(e.target.value)
                                      }
                                    />
                                    <label htmlFor="CorreoTecnico">
                                      Correo electrónico del contacto técnico:
                                    </label>
                                  </div>
                                  <div className="form-floating mb-3">
                                    <input
                                      id="NumeroTecnico"
                                      type="text"
                                      maxLength={40}
                                      placeholder="En caso de ser varios, separar con un guión (-)"
                                      className="form-control"
                                      value={NumeroTecnico}
                                      onChange={(e) =>
                                        setNumeroTecnico(e.target.value)
                                      }
                                    />
                                    <label htmlFor="NumeroTecnico">
                                      Número de teléfono móvil o fíjo del
                                      contacto técnico:
                                    </label>
                                  </div>
                                </>
                              )}
                              <div className="form-check form-switch text-start">
                                <label
                                  className="form-check-label"
                                  htmlFor="CuentaConAdministrativo"
                                >
                                  La sede cuenta con un contacto administrativo?
                                </label>
                                <input
                                  id="CuentaConAdministrativo"
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={CuentaConAdministrativo}
                                  onChange={(e) =>
                                    setCuentaConAdministrativo(e.target.checked)
                                  }
                                />
                              </div>
                              {CuentaConAdministrativo && (
                                <>
                                  <div className="form-floating mb-3">
                                    <input
                                      id="NombreAdministrativo"
                                      placeholder="Digite el nombre:"
                                      className="form-control"
                                      type="text"
                                      maxLength={150}
                                      value={NombreAdministrativo}
                                      onChange={(e) =>
                                        setNombreAdministrativo(e.target.value)
                                      }
                                    />
                                    <label htmlFor="NombreTecnico">
                                      Nombre completo del contacto
                                      administrativo
                                    </label>
                                  </div>
                                  <div className="form-floating mb-3">
                                    <input
                                      id="CorreoAdministrativo"
                                      placeholder="Ejemplo@correo.com"
                                      className="form-control"
                                      type="text"
                                      maxLength={150}
                                      value={CorreoAdministrativo}
                                      onChange={(e) =>
                                        setCorreoAdministrativo(e.target.value)
                                      }
                                    />
                                    <label htmlFor="CorreoTecnico">
                                      Correo electrónico del contacto
                                      administrativo:
                                    </label>
                                  </div>
                                  <div className="form-floating mb-3">
                                    <input
                                      id="NumeroAdministrativo"
                                      type="text"
                                      maxLength={40}
                                      placeholder="En caso de ser varios, separar con un guión (-)"
                                      className="form-control"
                                      value={NumeroAdministrativo}
                                      onChange={(e) =>
                                        setNumeroAdministrativo(e.target.value)
                                      }
                                    />
                                    <label htmlFor="NumeroTecnico">
                                      Número de teléfono móvil o fíjo del
                                      contacto administrativo:
                                    </label>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingRed">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseRed"
                              aria-expanded="false"
                              aria-controls="collapseRed"
                            >
                              Conectividad
                            </button>
                          </h2>
                          <div
                            id="collapseRed"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingRed"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body d-flex flex-column">
                              <div className="form-check form-switch text-start">
                                <label
                                  className="form-check-label"
                                  htmlFor="infraestructuraRed"
                                >
                                  La sede cuenta con Conexion a internet?
                                </label>
                                <input
                                  id="infraestructuraRed"
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={infraestructuraRed}
                                  onChange={(e) =>
                                    setInfraestructuraRed(e.target.checked)
                                  }
                                />
                              </div>
                              {infraestructuraRed && (
                                <>
                                  <div className="text-start mb-3">
                                    <h5 className="text-start">
                                      Señale los tipos de acceso a Internet
                                      disponibles actualmente (opción múltiple):
                                      Marque una o varias de las opciones:
                                    </h5>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="ConexionDiscado"
                                        checked={ConexionDiscado}
                                        onChange={(e) =>
                                          setConexionDiscado(e.target.checked)
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="ConexionDiscado"
                                      >
                                        Conexion por discado, que deja la línea
                                        telefónica ocupada durante el uso
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="ConexionDSL"
                                        checked={ConexionDSL}
                                        onChange={(e) =>
                                          setConexionDSL(e.target.checked)
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="ConexionDSL"
                                      >
                                        Conexion DSL, a través de línea
                                        telefónica, que no deja la línea ocupada
                                        durante su uso
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="ConexionCable"
                                        checked={ConexionCable}
                                        onChange={(e) =>
                                          setConexionCable(e.target.checked)
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="ConexionCable"
                                      >
                                        Conexion por cable
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="ConexionFibraOptica"
                                        checked={ConexionFibraOptica}
                                        onChange={(e) =>
                                          setConexionFibraOptica(
                                            e.target.checked
                                          )
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="ConexionFibraOptica"
                                      >
                                        Conexion por fibra óptica
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="ConexionSatelital"
                                        checked={ConexionSatelital}
                                        onChange={(e) =>
                                          setConexionSatelital(e.target.checked)
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="ConexionSatelital"
                                      >
                                        Conexion satelital
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="ConexionRadio"
                                        checked={ConexionRadio}
                                        onChange={(e) =>
                                          setConexionRadio(e.target.checked)
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="ConexionRadio"
                                      >
                                        Conexion por radio
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="ConexionMovil"
                                        checked={ConexionMovil}
                                        onChange={(e) =>
                                          setConexionMovil(e.target.checked)
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="ConexionMovil"
                                      >
                                        Conexion móvil o módem 3G o 4G
                                      </label>
                                    </div>
                                  </div>
                                  <div className="form-floating mb-3">
                                    <input
                                      id="OtraConexion"
                                      type="text"
                                      maxLength={255}
                                      placeholder="Ingrese el tipo de conexión: "
                                      className="form-control"
                                      value={OtraConexion}
                                      onChange={(e) =>
                                        setOtraConexion(e.target.value)
                                      }
                                    />
                                    <label htmlFor="OtraConexion">
                                      Otro tipo:
                                    </label>
                                  </div>
                                  <div className="input-group mb-3">
                                    <label
                                      className="input-group-text"
                                      htmlFor="tipoConexion"
                                    >
                                      Proveedor de Internet
                                    </label>
                                    <select
                                      className="form-select"
                                      id="tipoConexion"
                                      value={proveedorConexion}
                                      onChange={handleProveedorChange}
                                    >
                                      <option value="">
                                        Seleccione una opción
                                      </option>
                                      <option value="CLARO">Claro</option>
                                      <option value="ETB">ETB</option>
                                      <option value="MOVISTAR">Movistar</option>
                                      <option value="TIGO">Tigo</option>
                                      <option value="OTRO">Otro</option>
                                    </select>
                                  </div>
                                  {mostrarCampoOtro && (
                                    <div className="form-floating mb-3">
                                      <input
                                        id="ProveedorConexion"
                                        type="text"
                                        maxLength={100}
                                        placeholder="Ingrese el proveedor: "
                                        className="form-control"
                                        value={proveedorOtro}
                                        onChange={(e) =>
                                          setProveedorOtro(e.target.value)
                                        }
                                      />
                                      <label htmlFor="ProveedorConexion">
                                        Proveedor de la Conexion:
                                      </label>
                                    </div>
                                  )}
                                  <div className="input-group mb-3">
                                    <label
                                      className="input-group-text"
                                      htmlFor="anchoBanda"
                                    >
                                      Cuál es el ancho de banda o velocidad de
                                      la Conexion?
                                    </label>
                                    <select
                                      className="form-select"
                                      id="anchoBanda"
                                      value={anchoBanda}
                                      onChange={(e) =>
                                        setAnchoBanda(e.target.value)
                                      }
                                    >
                                      <option value="">
                                        Seleccione una opción
                                      </option>
                                      <option value="HASTA 256 KBPS">
                                        Hasta 256 Kbps
                                      </option>
                                      <option value="MÁS DE 256 KBPS HASTA 1 MBPS">
                                        Más de 256 Kbps hasta 1 Mbps
                                      </option>
                                      <option value="MÁS DE 1 MBPS HASTA 10 MBPS">
                                        Más de 1 Mbps hasta 10 Mbps
                                      </option>
                                      <option value="MÁS DE 10 MBPS HASTA 100 MBPS">
                                        Más de 10 Mbps hasta 100 Mbps
                                      </option>
                                      <option value="100 MBPS O MÁS">
                                        100 Mbps o más
                                      </option>
                                    </select>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingFirewall">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFirewall"
                              aria-expanded="false"
                              aria-controls="collapseFirewall"
                            >
                              Firewall
                            </button>
                          </h2>
                          <div
                            id="collapseFirewall"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFirewall"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body d-flex flex-column">
                              <div className="form-check form-switch text-start">
                                <label
                                  className="form-check-label"
                                  htmlFor="firewall"
                                >
                                  La sede cuenta con Firewall?
                                </label>
                                <input
                                  id="firewall"
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={firewall}
                                  onChange={(e) =>
                                    setFirewall(e.target.checked)
                                  }
                                />
                              </div>
                              {firewall && (
                                <>
                                  <div className="form-floating mb-3">
                                    <input
                                      id="FabricanteFirewall"
                                      type="text"
                                      maxLength={150}
                                      placeholder="Ingrese el fabricante: "
                                      className="form-control"
                                      value={FabricanteFirewall}
                                      onChange={(e) =>
                                        setFabricanteFirewall(e.target.value)
                                      }
                                    />
                                    <label htmlFor="FabricanteFirewall">
                                      Fabricante o marca del Firewall:
                                    </label>
                                  </div>
                                  <div className="form-floating mb-3">
                                    <input
                                      id="ModeloFirewall"
                                      type="text"
                                      maxLength={150}
                                      placeholder="Ingrese el modelo: "
                                      className="form-control"
                                      value={ModeloFirewall}
                                      onChange={(e) =>
                                        setModeloFirewall(e.target.value)
                                      }
                                    />
                                    <label htmlFor="FabricanteFirewall">
                                      Modelo del Firewall:
                                    </label>
                                  </div>
                                  <div className="form-check form-switch text-start">
                                    <label className="form-check-label">
                                      El Firewall se encuentra actualizado a su
                                      última versión?
                                    </label>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={UltimaVersionFirewall}
                                      onChange={(e) =>
                                        setUltimaVersionFirewall(
                                          e.target.checked
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="form-floating mb-3">
                                    <input
                                      id="VersionFirewall"
                                      type="text"
                                      maxLength={50}
                                      placeholder="Ingrese la versión: "
                                      className="form-control"
                                      value={versionFirewall}
                                      onChange={(e) =>
                                        setVersionFirewall(e.target.value)
                                      }
                                    />
                                    <label htmlFor="VersionFirewall">
                                      Versión actual del Firewall:
                                    </label>
                                  </div>
                                  <div className="form-check form-switch text-start">
                                    <label className="form-check-label">
                                      La sede cuenta con personal capacitado
                                      para configurar una VPN en este firewall?
                                    </label>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={PersonalVPN}
                                      onChange={(e) =>
                                        setPersonalVPN(e.target.checked)
                                      }
                                    />
                                  </div>
                                  {PersonalVPN && (
                                    <div>
                                      <div className="form-floating mb-3">
                                        <input
                                          id="NombreVPN"
                                          type="text"
                                          maxLength={150}
                                          placeholder="Ingrese el nombre: "
                                          className="form-control"
                                          value={NombreVPN}
                                          onChange={(e) =>
                                            setNombreVPN(e.target.value)
                                          }
                                        />
                                        <label htmlFor="NombreVPN">
                                          Nombre completo del encargado:
                                        </label>
                                      </div>
                                      <div className="form-floating mb-3">
                                        <input
                                          id="CorreoVPN"
                                          type="text"
                                          maxLength={150}
                                          placeholder="Ingrese el correo: "
                                          className="form-control"
                                          value={CorreoVPN}
                                          onChange={(e) =>
                                            setCorreoVPN(e.target.value)
                                          }
                                        />
                                        <label htmlFor="CorreoVPN">
                                          Correo electrónico del encargado:
                                        </label>
                                      </div>
                                      <div className="form-floating mb-3">
                                        <input
                                          id="NumeroVPN"
                                          type="text"
                                          maxLength={150}
                                          placeholder="Ingrese el numero: "
                                          className="form-control"
                                          value={NumeroVPN}
                                          onChange={(e) =>
                                            setNumeroVPN(e.target.value)
                                          }
                                        />
                                        <label htmlFor="NumeroVPN">
                                          Número de teléfono móvil o fíjo del
                                          encargado:
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                  <div className="form-check form-switch text-start">
                                    <label className="form-check-label">
                                      La sede cuenta con contratista configurar
                                      una VPN en este firewall?
                                    </label>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={ContratistaVPN}
                                      onChange={(e) =>
                                        setContratistaVPN(e.target.checked)
                                      }
                                    />
                                  </div>
                                  {ContratistaVPN && (
                                    <div>
                                      <div className="form-floating mb-3">
                                        <input
                                          id="NombreContratistaVPN"
                                          type="text"
                                          maxLength={150}
                                          placeholder="Ingrese el nombre: "
                                          className="form-control"
                                          value={NombreContratistaVPN}
                                          onChange={(e) =>
                                            setNombreContratistaVPN(
                                              e.target.value
                                            )
                                          }
                                        />
                                        <label htmlFor="NombreContratistaVPN">
                                          Nombre completo del encargado:
                                        </label>
                                      </div>
                                      <div className="form-floating mb-3">
                                        <input
                                          id="CorreoContratistaVPN"
                                          type="text"
                                          maxLength={150}
                                          placeholder="Ingrese el correo: "
                                          className="form-control"
                                          value={CorreoContratistaVPN}
                                          onChange={(e) =>
                                            setCorreoContratistaVPN(
                                              e.target.value
                                            )
                                          }
                                        />
                                        <label htmlFor="CorreoContratistaVPN">
                                          Correo electrónico del encargado:
                                        </label>
                                      </div>
                                      <div className="form-floating mb-3">
                                        <input
                                          id="NumeroContratistaVPN"
                                          type="text"
                                          maxLength={150}
                                          placeholder="Ingrese el numero: "
                                          className="form-control"
                                          value={NumeroContratistaVPN}
                                          onChange={(e) =>
                                            setNumeroContratistaVPN(
                                              e.target.value
                                            )
                                          }
                                        />
                                        <label htmlFor="NumeroContratistaVPN">
                                          Número de teléfono móvil o fíjo del
                                          encargado:
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingSoftware">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseSoftware"
                              aria-expanded="false"
                              aria-controls="collapseSoftware"
                            >
                              Sistemas de información - Historia clínica
                              electrónica
                            </button>
                          </h2>
                          <div
                            id="collapseSoftware"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingSoftware"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body d-flex flex-column">
                              <div className="form-check form-switch text-start">
                                <label
                                  className="form-check-label"
                                  htmlFor="software"
                                >
                                  La sede cuenta con Software de historia
                                  clínica electrónica?
                                </label>
                                <input
                                  id="software"
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={software}
                                  onChange={(e) =>
                                    setSoftware(e.target.checked)
                                  }
                                />
                              </div>
                              {software && (
                                <>
                                  <div className="form-floating mb-3">
                                    <input
                                      id="NombreSoftware"
                                      type="text"
                                      maxLength={150}
                                      placeholder="Ingrese el nombre: "
                                      className="form-control"
                                      value={NombreSoftware}
                                      onChange={(e) =>
                                        setNombreSoftware(e.target.value)
                                      }
                                    />
                                    <label htmlFor="NombreSoftware">
                                      Nombre del Sistema de información:
                                    </label>
                                  </div>
                                  <div className="form-floating mb-3">
                                    <input
                                      id="FabricanteSoftware"
                                      type="text"
                                      maxLength={150}
                                      placeholder="Ingrese el nombre: "
                                      className="form-control"
                                      value={FabricanteSoftware}
                                      onChange={(e) =>
                                        setFabricanteSoftware(e.target.value)
                                      }
                                    />
                                    <label htmlFor="FabricanteSoftware">
                                      Nombre del proveedor:
                                    </label>
                                  </div>
                                  <div className="input-group mb-3">
                                    <label
                                      className="input-group-text"
                                      htmlFor="Modalidad"
                                    >
                                      Qué modalidad de funcionamiento tiene el
                                      software?
                                    </label>
                                    <select
                                      className="form-select"
                                      id="Modalidad"
                                      value={Modalidad}
                                      onChange={(e) =>
                                        setModalidad(e.target.value)
                                      }
                                    >
                                      <option value="">
                                        Seleccione una opción
                                      </option>
                                      <option value="COMO SERVICIO">
                                        Software como servicio (Alquiler)
                                      </option>
                                      <option value="ON-PREMISE">
                                        Software On-premise (Instalado y
                                        ejecutado en las instalaciones de la
                                        sede)
                                      </option>
                                    </select>
                                  </div>
                                  <div className="input-group mb-3">
                                    <label
                                      className="input-group-text"
                                      htmlFor="TipoLicencia"
                                    >
                                      Tipo de licenciamiento del software:
                                    </label>
                                    <select
                                      className="form-select"
                                      id="TipoLicencia"
                                      value={TipoLicencia}
                                      onChange={(e) =>
                                        setTipoLicencia(e.target.value)
                                      }
                                    >
                                      <option value="">
                                        Seleccione una opción
                                      </option>
                                      <option value="ARRIENDO">Arriendo</option>
                                      <option value="PROPIO">Propio</option>
                                    </select>
                                  </div>
                                  <div className="form-floating mb-3">
                                    <input
                                      id="CorreoComercial"
                                      type="text"
                                      maxLength={150}
                                      placeholder="Ingrese el Correo "
                                      className="form-control"
                                      value={CorreoComercial}
                                      onChange={(e) =>
                                        setCorreoComercial(e.target.value)
                                      }
                                    />
                                    <label htmlFor="CorreoComercial">
                                      Correo comercial del fabricante:
                                    </label>
                                  </div>
                                  <div className="form-floating mb-3">
                                    <input
                                      id="CorreoAsistencia"
                                      type="text"
                                      maxLength={150}
                                      placeholder="Ingrese el correo: "
                                      className="form-control"
                                      value={CorreoAsistencia}
                                      onChange={(e) =>
                                        setCorreoAsistencia(e.target.value)
                                      }
                                    />
                                    <label htmlFor="CorreoAsistencia">
                                      Correo electrónico de asistencia técnica
                                      del fabricante:
                                    </label>
                                  </div>
                                  <div className="form-floating mb-3">
                                    <input
                                      id="NumeroComercial"
                                      type="text"
                                      maxLength={150}
                                      placeholder="Ingrese el numero: "
                                      className="form-control"
                                      value={NumeroComercial}
                                      onChange={(e) =>
                                        setNumeroComercial(e.target.value)
                                      }
                                    />
                                    <label htmlFor="NumeroComercial">
                                      Número de teléfono móvil o fíjo comercial
                                      del fabricante:
                                    </label>
                                  </div>
                                  <div className="form-floating mb-3">
                                    <input
                                      id="NumeroAsistencia"
                                      type="text"
                                      maxLength={150}
                                      placeholder="Ingrese el numero: "
                                      className="form-control"
                                      value={NumeroAsistencia}
                                      onChange={(e) =>
                                        setNumeroAsistencia(e.target.value)
                                      }
                                    />
                                    <label htmlFor="NumeroAsistencia">
                                      Número de teléfono móvil o fíjo de
                                      asistencia técnica del fabricante:
                                    </label>
                                  </div>
                                  <div className="form-floating mb-3">
                                    <input
                                      id="NombrePerSistema"
                                      type="text"
                                      maxLength={150}
                                      placeholder="Ingrese el Nombre "
                                      className="form-control"
                                      value={NombrePerSistema}
                                      onChange={(e) =>
                                        setNombrePerSistema(e.target.value)
                                      }
                                    />
                                    <label htmlFor="CorreoComercial">
                                      Nombre del referente o contacto en la sede
                                      para temas relacionados con el sistema:
                                    </label>
                                  </div>
                                  <div className="form-floating mb-3">
                                    <input
                                      id="CorreoPerSistema"
                                      type="text"
                                      maxLength={150}
                                      placeholder="Ingrese el Correo "
                                      className="form-control"
                                      value={CorreoPerSistema}
                                      onChange={(e) =>
                                        setCorreoPerSistema(e.target.value)
                                      }
                                    />
                                    <label htmlFor="CorreoPerSistema">
                                      Correo electrónico del referente o
                                      contacto en la sede para temas
                                      relacionados con el sistema:
                                    </label>
                                  </div>
                                  <div className="form-floating mb-3">
                                    <input
                                      id="NumeroPerSistema"
                                      type="text"
                                      maxLength={150}
                                      placeholder="Ingrese el Numero "
                                      className="form-control"
                                      value={NumeroPerSistema}
                                      onChange={(e) =>
                                        setNumeroPerSistema(e.target.value)
                                      }
                                    />
                                    <label htmlFor="NumeroPerSistema">
                                      Teléfono de contacto del referente o
                                      contacto en la sede para temas
                                      relacionados con el sistema:
                                    </label>
                                  </div>
                                  <div className="form-floating mb-4">
                                    <input
                                      id="CargoPerSistema"
                                      type="text"
                                      maxLength={150}
                                      placeholder="Ingrese el Cargo "
                                      className="form-control"
                                      value={CargoPerSistema}
                                      onChange={(e) =>
                                        setCargoPerSistema(e.target.value)
                                      }
                                    />
                                    <label htmlFor="CargoPerSistema">
                                      Cargo del referente o contacto en la sede
                                      para temas relacionados con el sistema:
                                    </label>
                                  </div>
                                  <h5 className="text-start">
                                    En cuanto a los registros electrónicos de
                                    pacientes, indique qué tipo de información
                                    está incluida (selección múltiple):
                                  </h5>
                                  <div className="form-check text-start">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="InfoDemo"
                                      checked={InfoDemo}
                                      onChange={(e) =>
                                        setInfoDemo(e.target.checked)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="InfoDemo"
                                    >
                                      Información demográfica del paciente
                                      (dirección, teléfono, fecha de nacimiento,
                                      etc.)
                                    </label>
                                  </div>

                                  <div className="form-check text-start">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="Diagnosticos"
                                      checked={Diagnosticos}
                                      onChange={(e) =>
                                        setDiagnosticos(e.target.checked)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="Diagnosticos"
                                    >
                                      Diagnósticos
                                    </label>
                                  </div>

                                  <div className="form-check text-start">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="Antecedentes"
                                      checked={Antecedentes}
                                      onChange={(e) =>
                                        setAntecedentes(e.target.checked)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="Antecedentes"
                                    >
                                      Antecedentes médicos
                                    </label>
                                  </div>

                                  <div className="form-check text-start">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="Alergias"
                                      checked={Alergias}
                                      onChange={(e) =>
                                        setAlergias(e.target.checked)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="Alergias"
                                    >
                                      Alergias conocidas del paciente
                                    </label>
                                  </div>

                                  <div className="form-check text-start">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="SignosVitales"
                                      checked={SignosVitales}
                                      onChange={(e) =>
                                        setSignosVitales(e.target.checked)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="SignosVitales"
                                    >
                                      Datos sobre los signos vitales del
                                      paciente
                                    </label>
                                  </div>

                                  <div className="form-check text-start">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="Medicamentos"
                                      checked={Medicamentos}
                                      onChange={(e) =>
                                        setMedicamentos(e.target.checked)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="Medicamentos"
                                    >
                                      Lista de medicamentos recetados
                                    </label>
                                  </div>

                                  <div className="form-check text-start">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="Vacunas"
                                      checked={Vacunas}
                                      onChange={(e) =>
                                        setVacunas(e.target.checked)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="Vacunas"
                                    >
                                      Registro de vacunas del paciente
                                    </label>
                                  </div>

                                  <div className="form-check text-start">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="NotasEnfermeria"
                                      checked={NotasEnfermeria}
                                      onChange={(e) =>
                                        setNotasEnfermeria(e.target.checked)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="NotasEnfermeria"
                                    >
                                      Notas de enfermería sobre el paciente
                                    </label>
                                  </div>

                                  <div className="form-check text-start">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="Resultados"
                                      checked={Resultados}
                                      onChange={(e) =>
                                        setResultados(e.target.checked)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="Resultados"
                                    >
                                      Resultados de análisis de laboratorio
                                    </label>
                                  </div>

                                  <div className="form-check text-start">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="InformesRadiologicos"
                                      checked={InformesRadiologicos}
                                      onChange={(e) =>
                                        setInformesRadiologicos(
                                          e.target.checked
                                        )
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="InformesRadiologicos"
                                    >
                                      Informes de estudios radiológicos
                                    </label>
                                  </div>

                                  <div className="form-check text-start">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="ImagesRadiologicos"
                                      checked={ImagesRadiologicos}
                                      onChange={(e) =>
                                        setImagesRadiologicos(e.target.checked)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="ImagesRadiologicos"
                                    >
                                      Imágenes de estudios radiológicos
                                    </label>
                                  </div>

                                  <div className="form-check text-start">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="Detalles"
                                      checked={Detalles}
                                      onChange={(e) =>
                                        setDetalles(e.target.checked)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="Detalles"
                                    >
                                      Detalles de admisión, traslado y alta del
                                      paciente
                                    </label>
                                  </div>

                                  <div className="form-check text-start">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="RegistrosPrevios"
                                      checked={RegistrosPrevios}
                                      onChange={(e) =>
                                        setRegistrosPrevios(e.target.checked)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="RegistrosPrevios"
                                    >
                                      Registros previos de atención
                                    </label>
                                  </div>
                                  <div className="form-floating mb-4">
                                    <input
                                      id="OtrosRegistros"
                                      type="text"
                                      maxLength={150}
                                      placeholder="Ingresa los registros: "
                                      className="form-control"
                                      value={OtrosRegistros}
                                      onChange={(e) =>
                                        setOtrosRegistros(e.target.value)
                                      }
                                    />
                                    <label htmlFor="OtrosRegistros">
                                      Otros:
                                    </label>
                                  </div>
                                  <div className="form-check form-switch text-start">
                                    <label className="form-check-label">
                                      El Software está actualizado a su última
                                      versión?
                                    </label>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={UltimaVersionSoftware}
                                      onChange={(e) =>
                                        setUltimaVersionSoftware(
                                          e.target.checked
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="form-floating mb-3">
                                    <input
                                      id="VersionSoftware"
                                      type="text"
                                      maxLength={150}
                                      placeholder="Ingrese la versión: "
                                      className="form-control"
                                      value={versionSoftware}
                                      onChange={(e) =>
                                        setVersionSoftware(e.target.value)
                                      }
                                    />
                                    <label htmlFor="VersionSoftware">
                                      Versión del Software:
                                    </label>
                                  </div>
                                  <div className="form-check form-switch text-start">
                                    <label className="form-check-label">
                                      Se cuenta actualmente con contrato de
                                      mantenimiento y soporte del software?
                                    </label>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={ContratoSoftware}
                                      onChange={(e) =>
                                        setContratoSoftware(e.target.checked)
                                      }
                                    />
                                  </div>
                                  {ContratoSoftware && (
                                    <>
                                      <div className="form-floating mb-3">
                                        <input
                                          id="FechaVence"
                                          type="text"
                                          maxLength={50}
                                          placeholder="Ingrese la fecha: "
                                          className="form-control"
                                          value={FechaVence}
                                          onChange={(e) =>
                                            setFechaVence(e.target.value)
                                          }
                                        />
                                        <label htmlFor="FechaVence">
                                          Fecha fin de vigencia del contrato
                                          aaaa-mm-dd:
                                        </label>
                                      </div>
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id="headingInfraestructura"
                          >
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseInfraestructura"
                              aria-expanded="false"
                              aria-controls="collapseInfraestructura"
                            >
                              Infraestructura
                            </button>
                          </h2>
                          <div
                            id="collapseInfraestructura"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingInfraestructura"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body d-flex flex-column">
                              <div className="form-check form-switch text-start">
                                <label
                                  className="form-check-label"
                                  htmlFor="CuentaConDataCenter"
                                >
                                  La sede cuenta con un sistema de DataCenter
                                  para administración de la información de las
                                  Historias Clínicas provenientes del prestador
                                  de salud.
                                </label>
                                <input
                                  id="CuentaConDataCenter"
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={CuentaConDataCenter}
                                  onChange={(e) =>
                                    setCuentaConDataCenter(e.target.checked)
                                  }
                                />
                              </div>
                              {CuentaConDataCenter && (
                                <>
                                  <h5 className="text-start">
                                    ¿Con qué características cuenta dicho
                                    Datacenter?
                                  </h5>
                                  <div className="form-check text-start">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="Escalable"
                                      checked={Escalable}
                                      onChange={(e) =>
                                        setEscalable(e.target.checked)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="Escalable"
                                    >
                                      Escalable
                                    </label>
                                  </div>
                                  <div className="form-check text-start">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="AltaDisponibilidad"
                                      checked={AltaDisponibilidad}
                                      onChange={(e) =>
                                        setAltaDisponibilidad(e.target.checked)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="AltaDisponibilidad"
                                    >
                                      Alta disponibilidad
                                    </label>
                                  </div>
                                  <div className="form-check text-start">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="Seguridad"
                                      checked={Seguridad}
                                      onChange={(e) =>
                                        setSeguridad(e.target.checked)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="Seguridad"
                                    >
                                      Seguridad
                                    </label>
                                  </div>
                                  <div className="form-check text-start">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="ConectividadRobusta"
                                      checked={ConectividadRobusta}
                                      onChange={(e) =>
                                        setConectividadRobusta(e.target.checked)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="ConectividadRobusta"
                                    >
                                      Conectividad robusta
                                    </label>
                                  </div>
                                  <div className="form-check text-start">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="GestionCentralizada"
                                      checked={GestionCentralizada}
                                      onChange={(e) =>
                                        setGestionCentralizada(e.target.checked)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="GestionCentralizada"
                                    >
                                      Gestión centralizada
                                    </label>
                                  </div>
                                  <div className="form-check text-start">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="Virtualizacion"
                                      checked={Virtualizacion}
                                      onChange={(e) =>
                                        setVirtualizacion(e.target.checked)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="Virtualizacion"
                                    >
                                      Virtualización
                                    </label>
                                  </div>
                                  <div className="form-check text-start">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="RespaldoRecuperacion"
                                      checked={RespaldoRecuperacion}
                                      onChange={(e) =>
                                        setRespaldoRecuperacion(
                                          e.target.checked
                                        )
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="RespaldoRecuperacion"
                                    >
                                      Respaldo y recuperación de datos
                                    </label>
                                  </div>
                                  <div className="form-floating mb-4">
                                    <input
                                      id="OtrasData"
                                      type="text"
                                      maxLength={180}
                                      placeholder="Ingresa las características: "
                                      className="form-control"
                                      value={OtrasData}
                                      onChange={(e) =>
                                        setOtrasData(e.target.value)
                                      }
                                    />
                                    <label htmlFor="OtrasData">Otras:</label>
                                  </div>
                                </>
                              )}
                              <div className="input-group mb-3">
                                <label
                                  className="input-group-text"
                                  htmlFor="CuentaConNube"
                                >
                                  Cuenta con servicios en la nube?
                                </label>
                                <select
                                  className="form-select "
                                  id="CuentaConNube"
                                  value={CuentaConNube}
                                  onChange={(e) =>
                                    setCuentaConNube(e.target.value)
                                  }
                                >
                                  <option value="">
                                    Seleccione una opción
                                  </option>
                                  <option value="SI">SI</option>
                                  <option value="NO">NO</option>
                                  <option value="NO SABE">No sabe</option>
                                </select>
                              </div>
                              {CuentaConNube === "SI" && (
                                <>
                                  <h5 className="text-start">
                                    Señale los servicios en nube utilizados en
                                    este establecimiento:
                                  </h5>
                                  <div className="form-check text-start">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="IaaS"
                                      checked={IaaS}
                                      onChange={(e) =>
                                        setIaaS(e.target.checked)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="IaaS"
                                    >
                                      Infraestructura como Servicio (IaaS)
                                    </label>
                                  </div>

                                  <div className="form-check text-start">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="PaaS"
                                      checked={PaaS}
                                      onChange={(e) =>
                                        setPaaS(e.target.checked)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="PaaS"
                                    >
                                      Plataforma como Servicio (PaaS)
                                    </label>
                                  </div>

                                  <div className="form-check text-start">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="SaaS"
                                      checked={SaaS}
                                      onChange={(e) =>
                                        setSaaS(e.target.checked)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="SaaS"
                                    >
                                      Software como Servicio (SaaS)
                                    </label>
                                  </div>

                                  <div className="form-check text-start">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="AlmaNube"
                                      checked={AlmaNube}
                                      onChange={(e) =>
                                        setAlmaNube(e.target.checked)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="AlmaNube"
                                    >
                                      Almacenamiento en la nube
                                    </label>
                                  </div>

                                  <div className="form-check text-start">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="CorreoNube"
                                      checked={CorreoNube}
                                      onChange={(e) =>
                                        setCorreoNube(e.target.checked)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="CorreoNube"
                                    >
                                      Correo electrónico
                                    </label>
                                  </div>

                                  <div className="form-check text-start">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="OficinaNube"
                                      checked={OficinaNube}
                                      onChange={(e) =>
                                        setOficinaNube(e.target.checked)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="OficinaNube"
                                    >
                                      Software de oficina
                                    </label>
                                  </div>

                                  <div className="form-check text-start">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="DBNube"
                                      checked={DBNube}
                                      onChange={(e) =>
                                        setDBNube(e.target.checked)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="DBNube"
                                    >
                                      Almacenamiento de bases de datos o
                                      archivos
                                    </label>
                                  </div>

                                  <div className="form-check text-start">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="ProcesamientoNube"
                                      checked={ProcesamientoNube}
                                      onChange={(e) =>
                                        setProcesamientoNube(e.target.checked)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="ProcesamientoNube"
                                    >
                                      Capacidad de procesamiento
                                    </label>
                                  </div>
                                  <div className="form-floating mb-4">
                                    <input
                                      id="OtrasNube"
                                      type="text"
                                      maxLength={180}
                                      placeholder="Ingresa los servicios: "
                                      className="form-control"
                                      value={OtrasNube}
                                      onChange={(e) =>
                                        setOtrasNube(e.target.value)
                                      }
                                    />
                                    <label htmlFor="OtrasNube">Otras:</label>
                                  </div>
                                </>
                              )}
                              <div className="input-group mb-3">
                                <h5 className="text-start">
                                  ¿Cuenta con un ambiente de pruebas
                                  independiente del ambiente de producción en el
                                  sistema de historia clínica electrónica?
                                </h5>
                                <label
                                  className="input-group-text"
                                  htmlFor="AmbientesSeparados"
                                >
                                  Seleccione una opción:
                                </label>
                                <select
                                  className="form-select "
                                  id="AmbientesSeparados"
                                  value={AmbientesSeparados}
                                  onChange={(e) =>
                                    setAmbientesSeparados(e.target.value)
                                  }
                                >
                                  <option value="">
                                    Seleccione una opción
                                  </option>
                                  <option value="SI">SI</option>
                                  <option value="NO">NO</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id="headingTalentoHumano"
                          >
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTalentoHumano"
                              aria-expanded="false"
                              aria-controls="collapseTalentoHumano"
                            >
                              Talento Humano Relacionado con el Proyecto de
                              Interoperabilidad
                            </button>
                          </h2>
                          <div
                            id="collapseTalentoHumano"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingTalentoHumano"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body d-flex flex-column">
                              <h5 className="text-start">
                                ¿La entidad cuenta con un equipo o departamento
                                de TI dedicado y responsable de gestionar los
                                procesos de interoperabilidad?
                              </h5>
                              <div className="form-check text-start">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="equipoTIDedicado"
                                  id="equipoTIDedicado1"
                                  value="SÍ, HAY UN EQUIPO DE TI DEDICADO"
                                  checked={
                                    equipoTIDedicado ===
                                    "SÍ, HAY UN EQUIPO DE TI DEDICADO"
                                  }
                                  onChange={(e) =>
                                    setEquipoTIDedicado(e.target.value)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="equipoTIDedicado1"
                                >
                                  Sí, hay un equipo de TI dedicado
                                </label>
                              </div>
                              <div className="form-check text-start">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="equipoTIDedicado"
                                  id="equipoTIDedicado2"
                                  value="NO, NO HAY UN EQUIPO DE TI DEDICADO"
                                  checked={
                                    equipoTIDedicado ===
                                    "NO, NO HAY UN EQUIPO DE TI DEDICADO"
                                  }
                                  onChange={(e) =>
                                    setEquipoTIDedicado(e.target.value)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="equipoTIDedicado2"
                                >
                                  No, no hay un equipo de TI dedicado
                                </label>
                              </div>
                              <div className="form-check text-start">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="equipoTIDedicado"
                                  id="equipoTIDedicado3"
                                  value="NO SABE"
                                  checked={equipoTIDedicado === "NO SABE"}
                                  onChange={(e) =>
                                    setEquipoTIDedicado(e.target.value)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="equipoTIDedicado3"
                                >
                                  No sabe
                                </label>
                              </div>
                              <div className="form-check text-start mb-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="equipoTIDedicado"
                                  id="equipoTIDedicado4"
                                  value="NO RESPONDE"
                                  checked={equipoTIDedicado === "NO RESPONDE"}
                                  onChange={(e) =>
                                    setEquipoTIDedicado(e.target.value)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="equipoTIDedicado4"
                                >
                                  No responde
                                </label>
                              </div>
                              <h5 className="text-start">
                                ¿Existen roles o puestos específicos dentro del
                                equipo de TI centrados en la interoperabilidad,
                                como especialistas en integración o arquitectos
                                de datos?
                              </h5>
                              <div className="form-check text-start">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="RolesEspecializados"
                                  id="RolesEspecializados1"
                                  value="SÍ, HAY ROLES ESPECIALIZADOS"
                                  checked={
                                    RolesEspecificos ===
                                    "SÍ, HAY ROLES ESPECIALIZADOS"
                                  }
                                  onChange={(e) =>
                                    setRolesEspecificos(e.target.value)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="RolesEspecializados1"
                                >
                                  Sí, hay roles especializados
                                </label>
                              </div>
                              <div className="form-check text-start">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="RolesEspecializados"
                                  id="RolesEspecializados2"
                                  value="NO, NO EXISTEN ROLES ESPECIALIZADOS"
                                  checked={
                                    RolesEspecificos ===
                                    "NO, NO EXISTEN ROLES ESPECIALIZADOS"
                                  }
                                  onChange={(e) =>
                                    setRolesEspecificos(e.target.value)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="RolesEspecializados2"
                                >
                                  No, no existen roles especializados
                                </label>
                              </div>
                              <div className="form-check text-start mb-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="RolesEspecializados"
                                  id="RolesEspecializados3"
                                  value="NO SABE"
                                  checked={RolesEspecificos === "NO SABE"}
                                  onChange={(e) =>
                                    setRolesEspecificos(e.target.value)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="RolesEspecializados3"
                                >
                                  No sabe
                                </label>
                              </div>
                              <h5 className="text-start">
                                ¿Cómo colabora el equipo de TI con otras áreas o
                                partes interesadas para garantizar una
                                interoperabilidad exitosa?
                              </h5>
                              <div className="form-check text-start">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="colaboracionTI"
                                  id="colaboracionTI1"
                                  value="COLABORACIÓN Y COMUNICACIÓN PERIÓDICAS"
                                  checked={
                                    colaboracionTI ===
                                    "COLABORACIÓN Y COMUNICACIÓN PERIÓDICAS"
                                  }
                                  onChange={(e) =>
                                    setColaboracionTI(e.target.value)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="colaboracionTI1"
                                >
                                  Colaboración y comunicación periódicas
                                </label>
                              </div>
                              <div className="form-check text-start">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="colaboracionTI"
                                  id="colaboracionTI2"
                                  value="COLABORACIÓN OCASIONAL"
                                  checked={
                                    colaboracionTI === "COLABORACIÓN OCASIONAL"
                                  }
                                  onChange={(e) =>
                                    setColaboracionTI(e.target.value)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="colaboracionTI2"
                                >
                                  Colaboración ocasional
                                </label>
                              </div>
                              <div className="form-check text-start">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="colaboracionTI"
                                  id="colaboracionTI3"
                                  value="COLABORACIÓN LIMITADA"
                                  checked={
                                    colaboracionTI === "COLABORACIÓN LIMITADA"
                                  }
                                  onChange={(e) =>
                                    setColaboracionTI(e.target.value)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="colaboracionTI3"
                                >
                                  Colaboración limitada
                                </label>
                              </div>
                              <div className="form-check text-start">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="colaboracionTI"
                                  id="colaboracionTI4"
                                  value="NO SABE"
                                  checked={colaboracionTI === "NO SABE"}
                                  onChange={(e) =>
                                    setColaboracionTI(e.target.value)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="colaboracionTI4"
                                >
                                  No sabe
                                </label>
                              </div>
                              <div className="form-check text-start">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="colaboracionTI"
                                  id="colaboracionTI5"
                                  value="NO RESPONDE"
                                  checked={colaboracionTI === "NO RESPONDE"}
                                  onChange={(e) =>
                                    setColaboracionTI(e.target.value)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="colaboracionTI5"
                                >
                                  No responde
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id="headingSeguridad"
                          >
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseSeguridad"
                              aria-expanded="false"
                              aria-controls="collapseSeguridad"
                            >
                              Seguridad
                            </button>
                          </h2>
                          <div
                            id="collapseSeguridad"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingSeguridad"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body d-flex flex-column">
                              <h5 className="text-start">
                                Señale con cuáles herramientas de seguridad
                                electrónica cuenta el establecimiento de salud:
                              </h5>
                              <div className="form-check text-start">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="firmaDigital"
                                  checked={firmaDigital}
                                  onChange={(e) =>
                                    setFirmaDigital(e.target.checked)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="firmaDigital"
                                >
                                  Firma digital
                                </label>
                              </div>
                              <div className="form-check text-start">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="archivosCriptografiados"
                                  checked={archivosCriptografiados}
                                  onChange={(e) =>
                                    setArchivosCriptografiados(e.target.checked)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="archivosCriptografiados"
                                >
                                  Archivos y correos electrónicos
                                  criptografiados
                                </label>
                              </div>
                              <div className="form-check text-start">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="proteccionContraseñaArchivos"
                                  checked={proteccionContraseñaArchivos}
                                  onChange={(e) =>
                                    setProteccionContraseñaArchivos(
                                      e.target.checked
                                    )
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="proteccionContraseñaArchivos"
                                >
                                  Protección por contraseña de archivos enviados
                                  o recibidos
                                </label>
                              </div>
                              <div className="form-check text-start">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="proteccionContraseñaSistema"
                                  checked={proteccionContraseñaSistema}
                                  onChange={(e) =>
                                    setProteccionContraseñaSistema(
                                      e.target.checked
                                    )
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="proteccionContraseñaSistema"
                                >
                                  Protección por contraseñas para acceso al
                                  sistema electrónico
                                </label>
                              </div>
                              <div className="form-check text-start">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="antivirus"
                                  checked={antivirus}
                                  onChange={(e) =>
                                    setAntivirus(e.target.checked)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="antivirus"
                                >
                                  Antivirus
                                </label>
                              </div>
                              <div className="form-check text-start">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="firewallSE"
                                  checked={firewallSE}
                                  onChange={(e) =>
                                    setFirewallSE(e.target.checked)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="firewallSE"
                                >
                                  Firewall o cortafuegos
                                </label>
                              </div>
                              <div className="form-check text-start">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="criptografiaBaseDatos"
                                  checked={criptografiaBaseDatos}
                                  onChange={(e) =>
                                    setCriptografiaBaseDatos(e.target.checked)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="criptografiaBaseDatos"
                                >
                                  Criptografía de la base de datos
                                </label>
                              </div>
                              <div className="form-check text-start">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="certificadoDigital"
                                  checked={certificadoDigital}
                                  onChange={(e) =>
                                    setCertificadoDigital(e.target.checked)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="certificadoDigital"
                                >
                                  Certificado digital
                                </label>
                              </div>
                              <div className="form-check text-start">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="accesoBiometrico"
                                  checked={accesoBiometrico}
                                  onChange={(e) =>
                                    setAccesoBiometrico(e.target.checked)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="accesoBiometrico"
                                >
                                  Acceso biométrico al sistema electrónico
                                </label>
                              </div>
                              <div className="form-check text-start">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="dobleFactor"
                                  checked={dobleFactor}
                                  onChange={(e) =>
                                    setDobleFactor(e.target.checked)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="dobleFactor"
                                >
                                  Doble factor de autenticación
                                </label>
                              </div>
                              <div className="form-check text-start">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="dlp"
                                  checked={dlp}
                                  onChange={(e) => setDlp(e.target.checked)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="dlp"
                                >
                                  DLP - Protección contra filtraciones de
                                  información (Data Loss Protection/ Prevention)
                                </label>
                              </div>
                              <div className="form-check text-start mb-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="ninguna"
                                  checked={ninguna}
                                  onChange={(e) => setNinguna(e.target.checked)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="ninguna"
                                >
                                  Ninguna
                                </label>
                              </div>
                              <h5 className="text-start">
                                ¿Qué medidas se toman para garantizar la
                                integridad y seguridad de los datos durante los
                                procesos de interoperabilidad entre el prestador
                                y el ente superior? (sede principal o ente
                                territorial)
                              </h5>
                              <div className="form-check text-start">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="integridadSeguridadDatos"
                                  id="cifradoSeguridad"
                                  value="PROTOCOLOS DE CIFRADO Y SEGURIDAD DE DATOS"
                                  checked={
                                    integridadSeguridadDatos ===
                                    "PROTOCOLOS DE CIFRADO Y SEGURIDAD DE DATOS"
                                  }
                                  onChange={(e) =>
                                    setIntegridadSeguridadDatos(e.target.value)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="cifradoSeguridad"
                                >
                                  Protocolos de cifrado y seguridad de datos
                                </label>
                              </div>
                              <div className="form-check text-start">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="integridadSeguridadDatos"
                                  id="medidasLimitadas"
                                  value="MEDIDAS LIMITADAS PARA LA INTEGRIDAD Y SEGURIDAD DE LOS DATOS"
                                  checked={
                                    integridadSeguridadDatos ===
                                    "MEDIDAS LIMITADAS PARA LA INTEGRIDAD Y SEGURIDAD DE LOS DATOS"
                                  }
                                  onChange={(e) =>
                                    setIntegridadSeguridadDatos(e.target.value)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="medidasLimitadas"
                                >
                                  Medidas limitadas para la integridad y
                                  seguridad de los datos
                                </label>
                              </div>
                              <div className="form-check text-start">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="integridadSeguridadDatos"
                                  id="noSabeintegridad"
                                  value="NO SABE"
                                  checked={
                                    integridadSeguridadDatos === "NO SABE"
                                  }
                                  onChange={(e) =>
                                    setIntegridadSeguridadDatos(e.target.value)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="noSabeintegridad"
                                >
                                  No sabe
                                </label>
                              </div>
                              <div className="form-check text-start mb-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="integridadSeguridadDatos"
                                  id="noResponde"
                                  value="NO RESPONDE"
                                  checked={
                                    integridadSeguridadDatos === "NO RESPONDE"
                                  }
                                  onChange={(e) =>
                                    setIntegridadSeguridadDatos(e.target.value)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="noResponde"
                                >
                                  No responde
                                </label>
                              </div>
                              <h5 className="text-start">
                                ¿Existe algún mecanismo de monitoreo y registro
                                para detectar problemas de seguridad entre el
                                prestador y el ente superior? (sede principal o
                                ente territorial)
                              </h5>
                              <div className="form-check text-start">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="monitoreoRegistroSeguridad"
                                  id="mecanismosDedicados"
                                  value="SÍ, MECANISMOS DE SEGUIMIENTO Y REGISTRO DEDICADOS"
                                  checked={
                                    monitoreoRegistroSeguridad ===
                                    "SÍ, MECANISMOS DE SEGUIMIENTO Y REGISTRO DEDICADOS"
                                  }
                                  onChange={(e) =>
                                    setMonitoreoRegistroSeguridad(
                                      e.target.value
                                    )
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="mecanismosDedicados"
                                >
                                  Sí, mecanismos de seguimiento y registro
                                  dedicados
                                </label>
                              </div>
                              <div className="form-check text-start">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="monitoreoRegistroSeguridad"
                                  id="noMecanismosDedicados"
                                  value="NO HAY MECANISMOS DE SEGUIMIENTO Y REGISTRO DEDICADOS"
                                  checked={
                                    monitoreoRegistroSeguridad ===
                                    "NO HAY MECANISMOS DE SEGUIMIENTO Y REGISTRO DEDICADOS"
                                  }
                                  onChange={(e) =>
                                    setMonitoreoRegistroSeguridad(
                                      e.target.value
                                    )
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="noMecanismosDedicados"
                                >
                                  No hay mecanismos de seguimiento y registro
                                  dedicados
                                </label>
                              </div>
                              <div className="form-check text-start">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="monitoreoRegistroSeguridad"
                                  id="noSabe"
                                  value="NO SABE"
                                  checked={
                                    monitoreoRegistroSeguridad === "NO SABE"
                                  }
                                  onChange={(e) =>
                                    setMonitoreoRegistroSeguridad(
                                      e.target.value
                                    )
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="noSabe"
                                >
                                  No sabe
                                </label>
                              </div>
                              <div className="form-check text-start">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="monitoreoRegistroSeguridad"
                                  id="noRespondemonitoreo"
                                  value="NO RESPONDE"
                                  checked={
                                    monitoreoRegistroSeguridad === "NO RESPONDE"
                                  }
                                  onChange={(e) =>
                                    setMonitoreoRegistroSeguridad(
                                      e.target.value
                                    )
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="noRespondemonitoreo"
                                >
                                  No responde
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id="headingEstandares"
                          >
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseEstandares"
                              aria-expanded="false"
                              aria-controls="collapseEstandares"
                            >
                              Interoperabilidad - Estandares
                            </button>
                          </h2>
                          <div
                            id="collapseEstandares"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingEstandares"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body d-flex flex-column">
                              <h5 className="text-start">
                                Indique qué tipo de información comparte
                                electrónicamente con otras instituciones de
                                salud (envía y recibe)
                              </h5>
                              <div className="form-check text-start">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="estadoSalud"
                                  checked={estadoSalud}
                                  onChange={() => setEstadoSalud(!estadoSalud)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="estadoSalud"
                                >
                                  Información sobre el estado de salud del
                                  paciente, descrita por los profesionales
                                  asistenciales.
                                </label>
                              </div>
                              <div className="form-check text-start">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="solicitudesAtencion"
                                  checked={solicitudesAtencion}
                                  onChange={() =>
                                    setSolicitudesAtencion(!solicitudesAtencion)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="solicitudesAtencion"
                                >
                                  Solicitudes de atención médica, ya sea de su
                                  centro o de otros.
                                </label>
                              </div>
                              <div className="form-check text-start">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="resumenAtencion"
                                  checked={resumenAtencion}
                                  onChange={() =>
                                    setResumenAtencion(!resumenAtencion)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="resumenAtencion"
                                >
                                  Resumen de la atención brindada al paciente al
                                  darle el alta o al ser derivado a otro centro.
                                </label>
                              </div>
                              <div className="form-check text-start">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="medicamentosRecetados"
                                  checked={medicamentosRecetados}
                                  onChange={() =>
                                    setMedicamentosRecetados(
                                      !medicamentosRecetados
                                    )
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="medicamentosRecetados"
                                >
                                  Lista de los medicamentos recetados al
                                  paciente.
                                </label>
                              </div>
                              <div className="form-check text-start">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="resultadosAnalisis"
                                  checked={resultadosAnalisis}
                                  onChange={() =>
                                    setResultadosAnalisis(!resultadosAnalisis)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="resultadosAnalisis"
                                >
                                  Resultados de análisis de laboratorio
                                  realizados al paciente.
                                </label>
                              </div>
                              <div className="form-check text-start">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="resultadosImagenes"
                                  checked={resultadosImagenes}
                                  onChange={() =>
                                    setResultadosImagenes(!resultadosImagenes)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="resultadosImagenes"
                                >
                                  Resultados de estudios de imágenes médicas del
                                  paciente.
                                </label>
                              </div>
                              <div className="form-check text-start">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="planesCuidado"
                                  checked={planesCuidado}
                                  onChange={() =>
                                    setPlanesCuidado(!planesCuidado)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="planesCuidado"
                                >
                                  Planes de cuidado de enfermería para el
                                  paciente.
                                </label>
                              </div>
                              <div className="form-check text-start">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="ningunaInfo"
                                  checked={ningunaInfo}
                                  onChange={() => setNingunaInfo(!ningunaInfo)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="ningunaInfo"
                                >
                                  Ninguna
                                </label>
                              </div>
                              <div className="form-floating mb-4">
                                <input
                                  id="OtraInfo"
                                  type="text"
                                  maxLength={150}
                                  placeholder="Ingresa la información: "
                                  className="form-control"
                                  value={OtraInfo}
                                  onChange={(e) => setOtraInfo(e.target.value)}
                                />
                                <label htmlFor="OtraInfo">Otras:</label>
                              </div>
                              <h5 className="text-start">
                                ¿Qué herramientas y software utiliza la entidad
                                para facilitar la interoperabilidad, como
                                plataformas de integración o middleware?
                              </h5>
                              <div className="form-check text-start">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="plataformasIntegracion"
                                  checked={plataformasIntegracion}
                                  onChange={() =>
                                    setPlataformasIntegracion(
                                      !plataformasIntegracion
                                    )
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="plataformasIntegracion"
                                >
                                  Plataformas de integración dedicadas o
                                  middleware
                                </label>
                              </div>
                              <div className="form-check text-start">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="softwarePropositoGeneral"
                                  checked={softwarePropositoGeneral}
                                  onChange={() =>
                                    setSoftwarePropositoGeneral(
                                      !softwarePropositoGeneral
                                    )
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="softwarePropositoGeneral"
                                >
                                  Software de propósito general con algunas
                                  capacidades de integración
                                </label>
                              </div>
                              <div className="form-check text-start">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="sinHerramientas"
                                  checked={sinHerramientas}
                                  onChange={() =>
                                    setSinHerramientas(!sinHerramientas)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="sinHerramientas"
                                >
                                  No hay herramientas o software específicos
                                </label>
                              </div>
                              <div className="form-check text-start">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="noSabeHerra"
                                  checked={noSabeHerra}
                                  onChange={() => setNoSabeHerra(!noSabeHerra)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="noSabeHerra"
                                >
                                  No sabe
                                </label>
                              </div>
                              <div className="form-floating mb-4">
                                <input
                                  id="OtrasHerra"
                                  type="text"
                                  maxLength={150}
                                  placeholder="Ingresa las herramientas: "
                                  className="form-control"
                                  value={OtrasHerra}
                                  onChange={(e) =>
                                    setOtrasHerra(e.target.value)
                                  }
                                />
                                <label htmlFor="OtrasHerra">Otras:</label>
                              </div>
                              <h5 className="text-start">
                                ¿Existe una estrategia documentada o una hoja de
                                ruta para gestionar la interoperabilidad dentro
                                de la entidad?
                              </h5>
                              <div className="input-group mb-3">
                                <label
                                  className="input-group-text"
                                  htmlFor="Estrategia"
                                >
                                  Seleccione una opción:
                                </label>
                                <select
                                  className="form-select "
                                  id="Estrategia"
                                  value={Estrategia}
                                  onChange={(e) =>
                                    setEstrategia(e.target.value)
                                  }
                                >
                                  <option value="">
                                    Seleccione una opción
                                  </option>
                                  <option value="SÍ, UNA ESTRATEGIA BIEN DOCUMENTADA">
                                    Sí, una estrategia bien documentada
                                  </option>
                                  <option value="ALGUNA DOCUMENTACIÓN, PERO NO EXHAUSTIVA.">
                                    Alguna documentación, pero no exhaustiva.
                                  </option>
                                  <option value="NINGUNA ESTRATEGIA DOCUMENTADA">
                                    Ninguna estrategia documentada
                                  </option>
                                  <option value="NO SABE">No sabe</option>
                                </select>
                              </div>
                              <h5 className="text-start">
                                ¿Existen procesos o flujos de trabajo
                                establecidos para evaluar y seleccionar
                                soluciones de interoperabilidad?
                              </h5>
                              <div className="input-group mb-3">
                                <label
                                  className="input-group-text"
                                  htmlFor="ProcesoEstablecido"
                                >
                                  Seleccione una opción:
                                </label>
                                <select
                                  className="form-select "
                                  id="ProcesoEstablecido"
                                  value={ProcesoEstablecido}
                                  onChange={(e) =>
                                    setProcesoEstablecido(e.target.value)
                                  }
                                >
                                  <option value="">
                                    Seleccione una opción
                                  </option>
                                  <option value="SÍ, PROCESOS BIEN ESTABLECIDOS">
                                    Sí, procesos bien establecidos
                                  </option>
                                  <option value="ALGUNOS PROCESOS IMPLEMENTADOS">
                                    Algunos procesos implementados
                                  </option>
                                  <option value="NO HAY PROCESOS ESTABLECIDOS">
                                    No hay procesos establecidos
                                  </option>
                                  <option value="NO SABE">No sabe</option>
                                </select>
                              </div>
                              <h5 className="text-start">
                                ¿Qué medidas existen para monitorear y evaluar
                                el desempeño de los procesos de
                                interoperabilidad a lo largo del tiempo?
                              </h5>
                              <div className="input-group mb-3">
                                <label
                                  className="input-group-text"
                                  htmlFor="MedidaEvaluativa"
                                >
                                  Seleccione una opción:
                                </label>
                                <select
                                  className="form-select "
                                  id="MedidaEvaluativa"
                                  value={MedidaEvaluativa}
                                  onChange={(e) =>
                                    setMedidaEvaluativa(e.target.value)
                                  }
                                >
                                  <option value="">
                                    Seleccione una opción
                                  </option>
                                  <option value="SEGUIMIENTO Y EVALUACIÓN PERIÓDICOS">
                                    Seguimiento y evaluación periódicos
                                  </option>
                                  <option value="SEGUIMIENTO OCASIONAL">
                                    Seguimiento ocasional
                                  </option>
                                  <option value="NO EXISTEN MEDIDAS ESPECÍFICAS">
                                    No existen medidas específicas
                                  </option>
                                  <option value="NO SABE">No sabe</option>
                                </select>
                              </div>
                              <h5 className="text-start">
                                ¿Qué estándares y protocolos se utilizan para
                                facilitar la interoperabilidad entre sistemas?
                              </h5>
                              <div className="input-group mb-3">
                                <label
                                  className="input-group-text"
                                  htmlFor="EstandarProtocolo"
                                >
                                  Seleccione una opción:
                                </label>
                                <select
                                  className="form-select"
                                  id="EstandarProtocolo"
                                  value={EstandarProtocolo}
                                  onChange={handleProtocoloChange}
                                >
                                  <option value="">
                                    Seleccione una opción
                                  </option>
                                  <option value="PROTOCOLOS PROPIETARIOS">
                                    Protocolos propietarios
                                  </option>
                                  <option value="ESTÁNDAR HL7 VERSIÓN 2">
                                    Estándar HL7 versión 2
                                  </option>
                                  <option value="ESTÁNDAR HL7 - CDA">
                                    Estándar HL7 - CDA
                                  </option>
                                  <option value="ESTÁNDAR HL7 VERSIÓN FHIR">
                                    Estándar HL7 versión FHIR
                                  </option>
                                  <option value="NO SABE">No sabe</option>
                                  <option value="NO SE UTILIZAN ESTÁNDARES">
                                    No se utilizan estándares
                                  </option>
                                  <option value="OTRO">Otro</option>
                                </select>
                              </div>
                              {MostrarCampoOtropro && (
                                <div className="form-floating mb-3">
                                  <input
                                    id="OtroEstandarProtocolo"
                                    type="text"
                                    maxLength={150}
                                    placeholder="Ingrese el estandar o protocolo: "
                                    className="form-control"
                                    value={EstandarProtocoloOtro}
                                    onChange={(e) =>
                                      setEstandarProtocoloOtro(e.target.value)
                                    }
                                  />
                                  <label htmlFor="OtroEstandarProtocolo">
                                    Otro estandar o protocolo:
                                  </label>
                                </div>
                              )}
                              <h5 className="text-start">
                                ¿Existe algún mecanismo de monitoreo y registro
                                para detectar problemas de seguridad entre el
                                prestador y el ente superior? (sede principal o
                                ente territorial)
                              </h5>
                              <div className="input-group mb-3">
                                <label
                                  className="input-group-text"
                                  htmlFor="Mecanismo"
                                >
                                  Seleccione una opción:
                                </label>
                                <select
                                  className="form-select "
                                  id="Mecanismo"
                                  value={Mecanismo}
                                  onChange={(e) => setMecanismo(e.target.value)}
                                >
                                  <option value="">
                                    Seleccione una opcion
                                  </option>
                                  <option value="SÍ, MECANISMOS DE SEGUIMIENTO Y REGISTRO DEDICADOS">
                                    Sí, mecanismos de seguimiento y registro
                                    dedicados
                                  </option>
                                  <option value="NO HAY MECANISMOS DE SEGUIMIENTO Y REGISTRO DEDICADOS">
                                    No hay mecanismos de seguimiento y registro
                                    dedicados
                                  </option>
                                  <option value="NO SABE">No sabe</option>
                                  <option value="NO RESPONDE">
                                    No responde
                                  </option>
                                </select>
                              </div>
                              <h5 className="text-start">
                                ¿Cómo garantiza la entidad la interoperabilidad
                                entre diferentes aplicaciones y sistemas de
                                información en salud?
                              </h5>
                              <div className="input-group mb-3">
                                <label
                                  className="input-group-text"
                                  htmlFor="Garantiza"
                                >
                                  Seleccione una opción:
                                </label>
                                <select
                                  className="form-select "
                                  id="Garantiza"
                                  value={Garantiza}
                                  onChange={handleGarantizaChange}
                                >
                                  <option value="">
                                    Seleccione una opción
                                  </option>
                                  <option value="A TRAVÉS DE FORMATOS Y PROTOCOLOS DE DATOS ESTANDARIZADOS">
                                    A través de formatos y protocolos de datos
                                    estandarizados
                                  </option>
                                  <option value="USO DE SOLUCIONES DE COMPATIBILIDAD PATENTADAS">
                                    Uso de soluciones de compatibilidad
                                    patentadas
                                  </option>
                                  <option value="OTRAS">Otras</option>
                                </select>
                              </div>
                              {MostrarOtraGarantiza && (
                                <div className="form-floating mb-3">
                                  <input
                                    id="OtrasGarantiza"
                                    type="text"
                                    maxLength={150}
                                    placeholder="Ingrese la descripción: "
                                    className="form-control"
                                    value={OtrasGarantiza}
                                    onChange={(e) =>
                                      setOtrasGarantiza(e.target.value)
                                    }
                                  />
                                  <label htmlFor="OtrasGarantiza">Otras:</label>
                                </div>
                              )}
                              <h5 className="text-start">
                                ¿Cómo maneja la entidad el formato y la
                                transformación de datos para garantizar la
                                comunicación entre los sistemas?
                              </h5>
                              <div className="input-group mb-3">
                                <label
                                  className="input-group-text"
                                  htmlFor="Formato"
                                >
                                  Seleccione una opción:
                                </label>
                                <select
                                  className="form-select "
                                  id="Formato"
                                  value={Formato}
                                  onChange={handleFormatoChange}
                                >
                                  <option value="">
                                    Seleccione una opción
                                  </option>
                                  <option value="HERRAMIENTAS AUTOMATIZADAS DE TRANSFORMACIÓN Y FORMATO DE DATOS.">
                                    Herramientas automatizadas de transformación
                                    y formato de datos.
                                  </option>
                                  <option value="PROCESOS MANUALES DE FORMATEO Y TRANSFORMACIÓN DE DATOS.">
                                    Procesos manuales de formateo y
                                    transformación de datos.
                                  </option>
                                  <option value="INTERFACES">Interfaces</option>
                                  <option value="EXTRACCIÓN, TRANSFORMACIÓN Y CARGA (ETL)">
                                    Extracción, transformación y carga (ETL)
                                  </option>
                                  <option value="NINGUNO DE LOS ANTERIORES.">
                                    Ninguno de los anteriores.
                                  </option>
                                  <option value="OTRAS">Otras</option>
                                </select>
                              </div>
                              {MostrarOtrasFormato && (
                                <div className="form-floating mb-3">
                                  <input
                                    id="OtrasFormato"
                                    type="text"
                                    maxLength={150}
                                    placeholder="Ingrese los formatos: "
                                    className="form-control"
                                    value={OtrasFormato}
                                    onChange={(e) =>
                                      setOtrasFormato(e.target.value)
                                    }
                                  />
                                  <label htmlFor="OtrasFormato">Otras:</label>
                                </div>
                              )}
                              <h5 className="text-start">
                                ¿Existe un diccionario de datos centralizado o
                                un repositorio de metadatos para definir y
                                mantener la semántica de datos en todos los
                                sistemas?
                              </h5>
                              <div className="input-group mb-3">
                                <label
                                  className="input-group-text"
                                  htmlFor="Diccionario"
                                >
                                  Seleccione una opción:
                                </label>
                                <select
                                  className="form-select "
                                  id="Diccionario"
                                  value={Diccionario}
                                  onChange={handleDiccionarioChange}
                                >
                                  <option value="">
                                    Seleccione una opción
                                  </option>
                                  <option value="SÍ, UN DICCIONARIO DE DATOS CENTRALIZADO O UN REPOSITORIO DE METADATOS">
                                    Sí, un diccionario de datos centralizado o
                                    un repositorio de metadatos
                                  </option>
                                  <option value="NO HAY DICCIONARIO DE DATOS CENTRALIZADO NI REPOSITORIO DE METADATOS">
                                    No hay diccionario de datos centralizado ni
                                    repositorio de metadatos
                                  </option>
                                  <option value="NINGUNO DE LOS ANTERIORES.">
                                    Ninguno de los anteriores.
                                  </option>
                                  <option value="OTRAS">Otras</option>
                                </select>
                              </div>
                              {MostrarOtrasDiccionario && (
                                <div className="form-floating mb-3">
                                  <input
                                    id="OtrasDiccionario"
                                    type="text"
                                    maxLength={150}
                                    placeholder="Ingrese la descripción: "
                                    className="form-control"
                                    value={OtrasDiccionario}
                                    onChange={(e) =>
                                      setOtrasDiccionario(e.target.value)
                                    }
                                  />
                                  <label htmlFor="OtrasDiccionario">
                                    Otras:
                                  </label>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={handleCancelar}
                  >
                    Cancelar
                  </button>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={handleGuardarSede}
                  >
                    Guardar cambios
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Toaster closeButton richColors />
    </div>
  );
};

export default EncuestaESE;
