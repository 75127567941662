import React, { useState } from "react";
import Barra from "../Componentes/Barra";
import { useAuth } from "../Hooks/Autorizacion";
import useFetchUserData from "../Hooks/RedireccionamientoPorRoles";

const Envios = () => {
  const { user } = useAuth();
  const isLoading = useFetchUserData(user);

  return (
    <div className="d-flex flex-column vh-100">
      <Barra />
      {isLoading ? ( // Si isLoading es true, mostrar el spinner
        <div className="d-flex align-items-center justify-content-center vh-100">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Cargando...</span>
          </div>
        </div>
      ) : (
        // Si isLoading es false, mostrar el contenido del módulo
        <div className="d-flex flex-column align-items-center vh-100">
          <h1>Administrar envíos realizados</h1>
        </div>
      )}
    </div>
  );
};

export default Envios;
