import React, { useState } from "react";

const CambiarContraseñaForm = ({ onSubmit }) => {
  const [contraseñaNueva, setContraseñaNueva] = useState("");
  const [confirmarContraseña, setConfirmarContraseña] = useState("");
  const [contraseñaValida, setContraseñaValida] = useState(false);
  const [mensajeError, setMensajeError] = useState("");
  const [mostrarAlerta, setMostrarAlerta] = useState(true); // Estado para controlar la visibilidad de la alerta

  const handleContraseñaNuevaChange = (e) => {
    const nuevaContraseña = e.target.value;
    setContraseñaNueva(nuevaContraseña);
    validarContraseña(nuevaContraseña, confirmarContraseña);
  };

  const handleConfirmarContraseñaChange = (e) => {
    const nuevaConfirmacion = e.target.value;
    setConfirmarContraseña(nuevaConfirmacion);
    validarContraseña(contraseñaNueva, nuevaConfirmacion);
  };

  const validarContraseña = (contraseña, confirmacion) => {
    // Expresión regular para validar la contraseña
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+.])[A-Za-z\d!@#$%^&*()_+.]{8,}$/;
    setContraseñaValida(contraseña === confirmacion && regex.test(contraseña));

    if (!regex.test(contraseña)) {
      setMensajeError(
        "La contraseña debe tener al menos 8 caracteres y contener al menos una minúscula, una mayúscula, un número y un carácter especial"
      );
      setMostrarAlerta(true); // Mostrar la alerta
    } else if (contraseña !== confirmacion) {
      setMensajeError("Las contraseñas no coinciden");
      setMostrarAlerta(true); // Mostrar la alerta
    } else {
      setMensajeError("");
      setMostrarAlerta(false); // Ocultar la alerta
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (contraseñaValida) {
      // Enviar datos al backend
      onSubmit(contraseñaNueva);
    }
  };

  return (
    <form
      className="d-flex flex-column align-items-center justify-content-center mx-3 w-50"
      onSubmit={handleSubmit}
      style={{
        background: "rgba(7, 137, 48, 0.3)",
        padding: "5%",
        borderRadius: "20px",
      }}
    >
      <div className="mb-3 text-light text-center">
        <div>
          Inicio de sesión por primera vez, requiere cambiar la contraseña
        </div>
        <label htmlFor="contraseñaNueva" className="form-label">
          Contraseña nueva
        </label>
        <input
          type="password"
          className="form-control"
          id="contraseñaNueva"
          placeholder="Ingresa nueva contraseña"
          value={contraseñaNueva}
          onChange={handleContraseñaNuevaChange}
          required
        />
      </div>
      <div className="mb-3 text-light text-center">
        <label htmlFor="confirmarContraseña" className="form-label">
          Confirmar Contraseña
        </label>
        <input
          type="password"
          className="form-control"
          id="confirmarContraseña"
          placeholder="Ingrese nuevamente"
          value={confirmarContraseña}
          onChange={handleConfirmarContraseñaChange}
          required
        />
      </div>
      <div className="form-group text-center">
        <button
          className="btn btn-success"
          type="submit"
          disabled={!contraseñaValida}
        >
          Haz clic aquí para cambiar la contraseña
        </button>
      </div>
      {mostrarAlerta && mensajeError && (
        <div className="alert alert-danger my-3 text-center" role="alert">
          {mensajeError}
        </div>
      )}
    </form>
  );
};

export default CambiarContraseñaForm;
