import { Navigate } from "react-router-dom";
import { useAuth } from "../Hooks/Autorizacion";

export const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  if (!user) {
    // En caso de que el usuario no esté autentificado
    return <Navigate to="/" />;
  } else {
    return children;
  }
};
