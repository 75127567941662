import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const useFetchUserData = (user) => {
  const Navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Realizar la solicitud para obtener la información del usuario y su rol
    async function fetchUserData() {
      try {
        const response = await axios.get("/login/isAdmin", {
          params: {
            correo: user, // Envía el correo del usuario al backend
          },
        });
        const { isAdmin } = response.data; // Se obtiene el CodRol del usuario
        if (isAdmin === 2) {
          Navigate("/Validar", { replace: true });
        } else if (isAdmin === 3) {
          Navigate("/Encuesta", { replace: true });
        }
      } catch (error) {
        console.error("Error al obtener la información del usuario:", error);
      } finally {
        setIsLoading(false); // La solicitud ha terminado, por lo que establecemos isLoading en false
      }
    }

    fetchUserData();
  }, [user, Navigate]);

  return isLoading;
};

export default useFetchUserData;
