import React, { useState, useEffect } from "react";
import axios from "axios";
import Barra from "../Componentes/Barra";
import { useAuth } from "../Hooks/Autorizacion";

const PPNA = () => {
  const [datos, setDatos] = useState([]);
  const [paginaActual, setPaginaActual] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const elementosPorPagina = 10;
  const user = useAuth();
  useEffect(() => {
    fetchData();
  }, [paginaActual, searchTerm]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `/PPNA?page=${paginaActual}&perPage=${elementosPorPagina}&search=${searchTerm}&user=${user.user}`
      );
      setDatos(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handlePaginaSiguiente = () => {
    setPaginaActual(paginaActual + 1);
  };

  const handlePaginaAnterior = () => {
    if (paginaActual > 1) {
      setPaginaActual(paginaActual - 1);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="d-flex flex-column vh-100">
      <Barra />
      <div className="d-flex flex-column align-items-center vh-100">
        <h1>Tabla de referencia de Personas vinculadas</h1>
        <input
          className="form-control mr-sm-2"
          type="search"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Buscar por documento de identificación..."
        />
        <table className="table table-success table-striped table-hover mt-3 shadow-lg">
          <thead>
            <tr className="bg-success">
              <th scope="col">Codigo Municipio</th>
              <th scope="col">Tipo ID</th>
              <th scope="col">Documento</th>
              <th scope="col">Nombre</th>
            </tr>
          </thead>
          <tbody>
            {datos.map((dato, index) => (
              <tr key={index}>
                <td>{dato.CodMunicipio}</td>
                <td>{dato.CodTipoIdentificacion}</td>
                <td>{dato.NumeroIdentificacion}</td>
                <td>
                  {dato.PrimerNombreUsuario +
                    " " +
                    dato.SegundoNombreUsuario +
                    " " +
                    dato.PrimerApellidoUsuario +
                    " " +
                    dato.SegundoApellidoUsuario}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination-buttons">
          <button
            className="btn btn-light"
            onClick={handlePaginaAnterior}
            disabled={paginaActual === 1}
          >
            Anterior
          </button>
          <button className="btn btn-light" onClick={handlePaginaSiguiente}>
            Siguiente
          </button>
        </div>
      </div>
    </div>
  );
};

export default PPNA;
